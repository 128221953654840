import React, {Component} from 'react';
import { StyleSheet, Button, View, SafeAreaView, Text, Alert,Keyboard,ActivityIndicator,Dimensions,Platform } from 'react-native';
import AsyncStorage from '@react-native-community/async-storage';
import { Input } from 'react-native-elements';
import Logo from './components/Logo';
import * as global from './components/global';
const { width, height } = Dimensions.get('window');

class LoginScreen extends Component {
	constructor(props){
      super(props)
      this.state = {
        userEmail:'',
        userPassword:'',
        userEmail_err:false,
        userPassword_err:false,
        invalid_login_err:false,
        showbutton:true,
		uniqueValue: 1,
		reload: true,
		userlog:0
      }
	  global.user_id	=	0;
    }
	
	componentDidMount() {
		this.checkUserSignedIn();
	}
	
	async checkUserSignedIn(){
		try {
		   await AsyncStorage.getItem('user')
			.then((item) => {
				 if (item) {
					 
					 	let user = item;
						user = JSON.parse(user);
						this.setState({userlog:user.id});
						global.user_id	=	user.id;
						this.props.navigation.navigate('Home',{
							 user_id: user.id
						 });
				 }else{
					 global.user_id	=	0;
					this.setState({userlog:0});
				 }
			});	
		} catch (error) {
		  console.log(error);
		}
	}
	
	forceRemount(){
		this.setState(({ uniqueValue }) => ({
		  uniqueValue: uniqueValue + 1,reload:false,userlog:0
		}));
		this.checkUserSignedIn();
	}
	

    userLogin = () => {
		this.setState({showbutton:false});
		const{userEmail} = this.state;
		const{userPassword} = this.state;
		const{invalid_login_err} = this.state;		
		
		/* VALIDATE INPUTS*/
		
			if(userEmail==''){
				this.setState({userEmail_err:true});
			}else{
				let reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
				if (reg.test(userEmail) === false) {
					this.setState({userEmail_err:true});
					this.setState({showbutton:true});
					return;
				}else {
					this.setState({userEmail_err:false});
				}
			}
			
			if(userPassword==''){
				this.setState({userPassword_err:true});
				this.setState({showbutton:true});
				return;
			}else{
				this.setState({userPassword_err:false});
			}
			
			if(userEmail=='' || userPassword==''){
				this.setState({invalid_login_err: true});
				this.setState({showbutton:true});
				return;
			}

      fetch('https://app.portda.com/api/login',{
          method:'post',
          headers: {
                          'Content-Type'  : 'application/json',
                          'PortDA-Allowed-Users': 'PortDA_Team',
                          'Authorization' : 'Basic YWRtaW5AcG9ydGRhLmNvbTo1MGNjYWQxMjE3NTI4NDEyNzMwMDdlMTUwMTE3YWViMjI3OTVmMDU4MzllYTg3N2M0NDcxMTc3MTUwYWZlNzlk'
                    },
          body:JSON.stringify({
            userEmail:userEmail,
            userPassword:userPassword
          })
      })
      .then((response)=>response.json())
        .then((responseJson)=>{
            if(responseJson.status=='success'){
					this.setState({invalid_login_err: false});
					this.setState({login_success: true});
					
					AsyncStorage.multiSet([
						['user',JSON.stringify(responseJson.user)]
					]);

					AsyncStorage.multiGet(['user']).then((data) => {
						let user = data[0][1];
						user = JSON.parse(user);
						global.user_id	=	user.id;
						this.setState({showbutton:true});
						this.props.navigation.navigate('Home', {
							user_id: user.id,
						});
					});
			}else if(responseJson.status=='fail'){
				this.setState({invalid_login_err: true});
				this.setState({showbutton:true});
			}
            
        })
        .catch((error)=>{
          console.error(error);
        });
    }
	
	
  render () {
	  if(this.props.route.params){
		  if(this.props.route.params.logout && this.state.reload==true){
			  this.forceRemount();
		  }
	  }
	  
	  const{userEmail,userPassword,userEmail_err,userPassword_err,invalid_login_err,login_success} = this.state;
    return ([
      <View style={styles.container} key={this.state.uniqueValue}>
		<View style={styles.left} ></View>
		<View style={styles.center} >
			<Logo  />		
			<View style={{ width: (width>748) ? 400 : width,margin: 10,zIndex: 1}}><Input style={{ padding: '5px',outlineColor:"#fff"}}
			  placeholder='Enter Email'
			onChangeText = {userEmail =>this.setState({userEmail})}  
			/></View>
			{(userEmail_err)?<View style={{ width: (width>748) ? 400 : width,margin: 10,zIndex: 1}}><Text style={styles.error}>*Email Invalid / empty</Text></View>:null}
				
			<View style={{ width: (width>748) ? 400 : width,margin: 10,zIndex: 1}} ><Input style={{ padding: '5px',outlineColor:"#fff"}}
				secureTextEntry={true}
				placeholder='Enter password'
			onChangeText = {userPassword => this.setState({userPassword})} 
			/></View>
			{(userPassword_err)?<View style={{ width: (width>748) ? 400 : width,margin: 10,zIndex: 1}}><Text style={styles.error}>*Password is empty</Text></View>:null}
			<View style={{ width: (width>748) ? 400 : width,margin: 5,padding: 5,zIndex: 1,backgroundColor: '#146eb4',zIndex: 1}} >{(this.state.showbutton) ? <Button title="Login" key={1} color="#146eb4" onPress={this.userLogin} style={styles.button} />	:	<ActivityIndicator size="large" color="#fff"  />}</View>
			<View style={{ width: (width>748) ? 400 : width,margin: 5,padding: 5,zIndex: 1,backgroundColor: '#146eb4',zIndex: 1}} ><Button title="Signup" key={2}  color="#146eb4" onPress={()=>this.props.navigation.navigate('Signup')} style={styles.button} /></View>
			<View style={{ width: (width>748) ? 400 : width,margin: 5,padding: 5,zIndex: 1,backgroundColor: '#146eb4',zIndex: 1}} ><Button title="Home" key={2}  color="#146eb4" onPress={()=> this.props.navigation.navigate('Home', {
							user_id: this.state.userlog,
						})} style={styles.button} /></View>
			{(invalid_login_err) && <View style={{ width: (width>748) ? 400 : width,margin: 10,zIndex: 1}} ><Text style={styles.error}>*Username/Password is incorrect</Text></View>}
		</View>
        <View style={styles.right} ></View>
      </View>,
	  <View  >
			<Text style={{position: 'absolute', left: 0, right: 0, bottom: 25, textAlign:'center',justifyContent: 'center',alignItems: 'center',padding:5,zIndex: 0}}>© 2020 PortDA.com. All rights reserved</Text>
			<Text style={{position: 'absolute', left: 0, right: 0, bottom: 0, textAlign:'center',justifyContent: 'center',alignItems: 'center',padding:5,zIndex: 0}}>Powered by UGSA GROUP</Text>
		</View>
    ]);
  }
}


const styles = StyleSheet.create({
  container: {
    flex: 1,
	flexDirection: 'row',
    width: '100%',
    height: '100%',
	backgroundColor:'#FFFFFF',
  },
  left: {
	width: '30%',
	height: '100%',
  },
  center: {
	justifyContent: 'center',
	alignItems: 'center',
	width: '40%',
	height: '100%',
  },
  right: {
	justifyContent: 'center',
	alignItems: 'center',
	width: '30%',
	height: '100%',
  },
  button:{
    backgroundColor:'#146eb4',
	width: '400',
  },
  error: {
    color:'red',
	fontSize:12,
  },
  success: {
    color:'white',
	backgroundColor:'green',
	fontSize:12,
	padding:5
  }
});

export default LoginScreen;