import React, {Component} from 'react';
import { StyleSheet, Button, View, SafeAreaView, Text, Alert,Linking,Keyboard,Dimensions,ScrollView,Picker,TouchableOpacity,TouchableHighlight,FlatList,Image,Platform } from 'react-native';
import AsyncStorage from '@react-native-community/async-storage';
import { Input } from 'react-native-elements';
import Icons from 'react-native-vector-icons/AntDesign';
import { useNavigation } from '@react-navigation/native';
const { width, height } = Dimensions.get('window');
import Icon from 'react-native-vector-icons/Feather';
import ListScreen from '../ListScreen.js';
import * as global from './global';
import m from './Markers.js';
import TopBar from './TopBar';

const gotoPort=(map,marker,allmarkers,port_name,port_id,country_id)=>{
	map.flyTo({
			center: global.gmarkers[marker].getLngLat(), zoom: 3, speed: 10, curve: 1, easing(t) {
			  return t;
			}
		});
		
		
		if(global.gmarkerspopup[marker].isOpen()==false){			
			global.gmarkers[marker].togglePopup();
		}
		
		allmarkers.map((value) => {
			if(marker==value.marker){
				if(global.gmarkerspopup[marker].isOpen()==false){			
					global.gmarkers[marker].togglePopup();
				}
			}else{
				if(global.gmarkerspopup[value.marker].isOpen()==true){			
					global.gmarkers[value.marker].togglePopup();
				}
			}
		});
		
}

export default class Results extends Component {
	constructor(props) {
		super(props);
		this.state = {
			ports: [],
			markers: [],
			scrollViewWidth:0,
			scrollViewWidthInner:0,
			currentXOffset:0,
			currentXOffsetInner:0,
			xoffset:0,
			pressed: false,
			backgroundColor2: '#fff',
			color2: '#000',
			selectedButton: null
		};
	}
	
	componentDidMount() {
		const { ports } = this.state;	
			
		m.getmajorportspda().then(response => {
			var floors = [];
			var mark_ers = [];
			
			Object.keys(response).forEach(function(key) {
				mark_ers.push({marker: key});
				floors.push({
						marker: key,
						country_id: response[key].country_id,
						port_id: response[key].port_id,
						country_name: response[key].country_name,
						port_name: response[key].port_name,
						dwt: "10,000",
						pda: response[key].grand_total_usd,
						port_expenses: response[key].port_expenses,
						port_expenses_total: response[key].port_expenses_total,
						cargo_expenses: response[key].cargo_expenses,
						other_expenses: response[key].other_expenses,
						total_days: response[key].total_days,
						local_currency: response[key].currency
				});
			});
			if(floors.length>0){
				this.setState({ports: floors,markers:mark_ers});
			}
		});		
		
	}
	
	
	componentWillUnmount () {
		this.unmounted = true
	}
	
	_handleScrollInner = (event) => {		
		var newXOffset 	= 	event.nativeEvent.contentOffset.x;	
		this.setState({currentXOffsetInner:newXOffset});	
	}
	
	_handleScroll = (event) => {		
		var newXOffset 	= 	event.nativeEvent.contentOffset.x;		
		var eachItemOffset = this.state.scrollViewWidth;
		var _currentXOffset =  newXOffset / eachItemOffset;
		_currentXOffset	=	Math.ceil(_currentXOffset);
		var array,defaultarray=null;
		const { ports } = this.state;
		array = this.props.childName;		
		var filtertype = this.props.filtertype;		
		defaultarray=null;
		if(filtertype=='default'){
			defaultarray	=	ports;
		}else{
			if(global.global_filter_data_pdas!=null){
				defaultarray	=	global.global_filter_data_pdas;
			}else{
				defaultarray	=	ports;
			}
		}		
		console.log(global.global_port_id);
		var marker_slide	=	defaultarray[_currentXOffset].marker;
		var port_name		=	defaultarray[_currentXOffset].port_name;
		var dwt				=	defaultarray[_currentXOffset].dwt;
		var pda				=	defaultarray[_currentXOffset].pda;
		var port_id			=	defaultarray[_currentXOffset].port_id;
		var country_id		=	defaultarray[_currentXOffset].country_id;
		var navigation		=	this.props.navigation;
		this.setState({currentXOffset:newXOffset});
		//this.setglobalvalues(marker_slide,port_name,dwt,pda,port_id,country_id,navigation);
		this.flytoport(marker_slide,port_name,dwt,pda,port_id,country_id);		
	}

	leftArrow = () => {
		let eachItemOffset = this.state.scrollViewWidth;
		let _currentXOffset =  this.state.currentXOffset - eachItemOffset;
		this.refs.scrollView.scrollTo({x: _currentXOffset, y: 0, animated: true});
	}

	rightArrow = () => {
		var eachItemOffset = this.state.scrollViewWidth;
		let _currentXOffset =  this.state.currentXOffset + eachItemOffset;
		this.refs.scrollView.scrollTo({x: _currentXOffset, y: 0, animated: true});
	}
	
	leftArrowInner = () => {
		let eachItemOffset = this.state.scrollViewWidthInner / 8;
		let _currentXOffset =  this.state.currentXOffsetInner - eachItemOffset;
		this.refs.scrollViewInner.scrollTo({x: _currentXOffset, y: 0, animated: true});
	}

	rightArrowInner = () => {
		let eachItemOffset = this.state.scrollViewWidthInner / 8;
		let _currentXOffset =  this.state.currentXOffsetInner + eachItemOffset;
		this.refs.scrollViewInner.scrollTo({x: _currentXOffset, y: 0, animated: true})
	}
	
	
	flytoport(marker,port_name,dwt,pda,port_id,country_id){
		if(this.props.filtertype=='default'){
			var markers = this.state.ports;
		}else{
			if(global.global_filter_data_pdas!=null){
				var markers = global.global_filter_data_pdas;
			}else{
				var markers = this.state.ports;
			}
		}
		
		global.gmarkerspopup[marker].setHTML('<div style="margin:0;background: #fff;color: #000;font-size: 13px;text-align: left !important; padding: 5px !important; line-height: 1.5 !important;">'+port_name+'<br/>Port Charges - '+pda+' USD </div>');
		Platform.OS === "web" ?  gotoPort(global.global_map,marker,markers,port_name,port_id,country_id) : null;
	}
	
	
	setglobalvalues(marker,port_name,dwt,pda,port_id,country_id,navigation,port_expenses_total,port_expenses,cargo_expenses,other_expenses,total_days,local_currency){
		global.global_port_id				=	port_id;
		global.global_major_port_id			=	port_id;
		global.global_country_id			=	country_id;
		global.global_port_name				=	port_name;
		global.pdaamount					=	pda;
		global.port_expenses_total			=	port_expenses_total;
		global.port_expenses				=	port_expenses;
		global.cargo_expenses				=	cargo_expenses;
		global.other_expenses				=	other_expenses;
		global.total_days					=	total_days;
		global.local_currency				=	local_currency;
		this.flytoport(marker,port_name,dwt,pda,port_id,country_id);
		navigation.openDrawer();
	}
	
		renderSubElement(condition,navigation,array,port_name,portid,countryid,country_name){
			if(condition == 'posts'){
			   global.global_filter_data_posts	=	array;
			   /*global.global_major_port_id	=	portid;
			   global.global_port_name	=	port_name;
			   global.global_country_id	=	countryid;
			   global.global_major_country_name	=	country_name;*/
			   return (
			   <SafeAreaView style={{zIndex:10,flex:1,flexDirection: 'row',backgroundColor:'transparent',width:width/1.3}}>
						<FlatList
							data={array}
							keyExtractor={ (id, index) => index.toString() } horizontal={true}	
							renderItem={({ item }) => (
							  <TouchableOpacity style={{flex:1,flexDirection: 'row',backgroundColor:'#fff',color:'#000',width:265,height:130, borderWidth: 1,borderColor: "lightgrey",borderRadius: 10,margin:5,zIndex:1}} onPress={() => navigation.navigate('Root',
							   {screen: 'SinglePost',
							   params: {post:item.post,title:item.title,file:item.p.file,created:item.created,username:item.u.username,port_name:item.po.port_name,portid:item.port_id,country_name:item.co.country_name,countryid:item.po.country_id}}
							   )}>
								   <View style={{width: 100, height:130 }}>
										<Image
											style= {{flex:1 , width: undefined, height: undefined,resizeMode:'contain'}}
											source={{
											  uri: 'https://app.portda.com'+item.p.file,
											}}
											
									  />
								   </View>
								   <View style={{ marginBottom: 5, backgroundColor: 'white'}}>
										<Text style={{fontSize: 12,padding:5,color:'grey',width: 150}} numberOfLines={7}  >{item.post}</Text>
									</View>
								</TouchableOpacity>
							)}
							/>
				</SafeAreaView>);
		   }else if(condition == 'cargos'){
			   global.global_filter_data_cargos	=	array;
			   return (<SafeAreaView style={styles.container}>
						<FlatList
							data={array}
							keyExtractor={ (id, index) => index.toString() } horizontal={true}
							renderItem={({ item }) => (
							  <TouchableOpacity style={{flex:1,flexDirection: 'row',backgroundColor:'#fff',color:'#000',width:265, borderWidth: 1,borderColor: "lightgrey",borderRadius: 10,margin:5,zIndex:1}} onPress={() => navigation.navigate('Root',
							   {screen: 'SinglePost',
							   params: {post:item.post,title:item.title,file:item.p.file}}
							   )}>
								   <View style={{width: 100, height:100 }}>
										<Image
											style= {{flex:1 , width: undefined, height: undefined,resizeMode:'contain'}}
											source={{
											  uri: 'https://app.portda.com'+item.p.file,
											}}
											
									  />
								   </View>
								   <View style={{ marginBottom: 5, backgroundColor: 'white'}}>
										<Text style={{fontSize: 12,padding:5,color:'grey',width: 150}} numberOfLines={5}  >{item.post}</Text>
									</View>
								</TouchableOpacity>
							)}
							/>
				</SafeAreaView>);
		   }else if(condition == 'activevessels'){
				global.global_filter_data_activevessels	=	array;
			  return (<SafeAreaView style={styles.container}>
						<ScrollView style={styles.scrollView} pagingEnabled={true} horizontal={true}  showsHorizontalScrollIndicator={true}  onContentSizeChange={(w, h) => this.setState({scrollViewWidthInner:width})} scrollEnabled={true} >
							{array.map((prop, key) => {
							return (
							   <TouchableOpacity style={{backgroundColor:'#fff',color:'#000',width:180, borderWidth: 1,borderColor: "lightgrey",borderRadius: 10,margin:5,zIndex:1}} onPress={() => navigation.navigate('Root',
							   {screen: 'Active Vessels',
							   params: {user_email: prop.user_email,vessel_name: prop.vessel_name,dwt: prop.dwt,port_name: prop.port,etb: prop.etb
							   }}
							   )} key={key}>
									<View style={{flex:1,flexDirection: 'row'}}><Text style={{fontSize: 20,padding:5,color:'#146eb4',width: 175}}  numberOfLines={1} >{prop.vessel_name} </Text></View>
									<Text style={{fontSize: 17,padding:5,color:'grey'}}  >Vessel DWT - {prop.dwt.toLocaleString()} </Text>
									<Text style={{fontSize: 17,padding:5,color:'#000',fontWeight:'bold'}}  >ETA - {prop.etb}</Text>
								</TouchableOpacity>
							 );
						  })}
						</ScrollView>
				</SafeAreaView>);
		   }else if(condition == 'factories'){
				global.global_filter_data_companies	=	array;
			  return (<SafeAreaView style={styles.container}>
						<FlatList
							data={array}
							keyExtractor={ (id, index) => index.toString() } horizontal={true}
							renderItem={({ item }) => (
							  <TouchableOpacity style={{backgroundColor:'transparent',width:150,height:150,borderWidth: 1,borderColor: "lightgrey",borderRadius: 10,margin:5,zIndex:1}} onPress={() => Linking.openURL('http://'+item.weburl)}>
								   <Image
											style= {{flex:1 , width: 150,height: 150, resizeMode:'contain'}}
											source={{
											  uri: 'https://app.portda.com'+item.document,
											}}
									  />
								   {/*<View style={{ marginBottom: 5, backgroundColor: 'white',height:50}}>
										<View style={{flex:1,flexDirection: 'row'}}><Text style={{fontSize: 14,padding:5,color:'#f90',width: 200}}  numberOfLines={1} >{item.company_name}</Text></View>
								   </View>*/}
								</TouchableOpacity>
							)}
							/>
				</SafeAreaView>);
		   }else if(condition == 'companies'){
				global.global_filter_data_companies	=	array;
			  return (<SafeAreaView style={[styles.container,{width:(width>748)? width/1.25 : width/1.3}]}>
						<ScrollView style={{backgroundColor:'transparent',width:(width>748)? width/1.25 : width/1.3}}  pagingEnabled={true} horizontal={true} showsHorizontalScrollIndicator={true} ref="scrollViewInner"  onContentSizeChange={(width, height) => this.setState({scrollViewWidthInner:width})} scrollEnabled={true}  onScroll={this._handleScrollInner}  scrollEventThrottle={16}>
							<TouchableOpacity style={{backgroundColor:'#fff',color:'#000',width:180,height:130, borderWidth: 1,borderColor: "lightgrey",borderRadius: 10,margin:5,zIndex:1}} onPress={() => navigation.navigate('Root',
							   {screen:'Port Service', params: {category: "agency",clickedport:port_name }}
							   )}>
									
									<View style={styles.services} >
										<View><Icon name="user-check" size={35} color="black" /></View>
										<View><Text style={{fontSize: 15,padding:3,color:'#146eb4'}}  numberOfLines={2} >Agency</Text></View>
									</View>
							</TouchableOpacity>
							<TouchableOpacity style={{backgroundColor:'#fff',color:'#000',width:180,height:130, borderWidth: 1,borderColor: "lightgrey",borderRadius: 10,margin:5,zIndex:1}} onPress={() => navigation.navigate('Root',
							   {screen:'Port Service', params: {category: "transportation",clickedport:port_name }}
							   )}>
									
									<View style={styles.services} >
										<View><Icon name="box" size={35} color="black" /></View>
										<View><Text style={{fontSize: 15,padding:3,color:'#146eb4'}}  numberOfLines={2} >Transportation</Text></View>
									</View>
							</TouchableOpacity>
							
							<TouchableOpacity style={{backgroundColor:'#fff',color:'#000',width:180,height:130, borderWidth: 1,borderColor: "lightgrey",borderRadius: 10,margin:5,zIndex:1}} onPress={() => navigation.navigate('Root',
							   {screen:'Port Service', params: {category: "CustomClearance",clickedport:port_name }}
							   )}>
									<View style={styles.services} ><View><Icon name="check-square" size={35} color="black" /></View><View><Text style={{fontSize: 15,padding:3,color:'#146eb4'}}  numberOfLines={2} >Custom Clearance</Text></View></View>
							</TouchableOpacity>
							<TouchableOpacity style={{backgroundColor:'#fff',color:'#000',width:180,height:130, borderWidth: 1,borderColor: "lightgrey",borderRadius: 10,margin:5,zIndex:1}} onPress={() => navigation.navigate('Root',
							   {screen:'Port Service', params: {category: "Rental Equipments",clickedport:port_name }}
							   )}>
									<View style={styles.services} ><Icon name="package" size={35} color="black" /><Text style={{fontSize: 15,padding:3,color:'#146eb4'}}  numberOfLines={2} >Rental Equipments</Text></View>
							</TouchableOpacity>
							<TouchableOpacity style={{backgroundColor:'#fff',color:'#000',width:180,height:130, borderWidth: 1,borderColor: "lightgrey",borderRadius: 10,margin:5,zIndex:1}} onPress={() => navigation.navigate('Root',
							   {screen:'Port Service', params: {category: "Marine Services",clickedport:port_name }}
							   )}>
									<View style={styles.services} ><Icon name="anchor" size={35} color="black" /><Text style={{fontSize: 15,padding:3,color:'#146eb4'}}  numberOfLines={2} >Marine Services</Text></View>
							</TouchableOpacity>
							<TouchableOpacity style={{backgroundColor:'#fff',color:'#000',width:180,height:130, borderWidth: 1,borderColor: "lightgrey",borderRadius: 10,margin:5,zIndex:1}} onPress={() => navigation.navigate('Root',
							   {screen:'Port Service', params: {category: "Bunker Suppliers",clickedport:port_name }}
							   )}>
									<View style={styles.services} ><Icon name="aperture" size={35} color="black" /><Text style={{fontSize: 15,padding:3,color:'#146eb4'}}  numberOfLines={2} >Bunker Suppliers</Text></View>
							</TouchableOpacity>
							<TouchableOpacity style={{backgroundColor:'#fff',color:'#000',width:180,height:130, borderWidth: 1,borderColor: "lightgrey",borderRadius: 10,margin:5,zIndex:1}} onPress={() => navigation.navigate('Root',
							   {screen:'Port Service', params: {category: "Ship Chandlers",clickedport:port_name }}
							   )}>
									<View style={styles.services} ><Icon name="trending-up" size={35} color="black" /><Text style={{fontSize: 15,padding:3,color:'#146eb4'}}  numberOfLines={2} >Ship Chandlers</Text></View>
							</TouchableOpacity>
							<TouchableOpacity style={{backgroundColor:'#fff',color:'#000',width:180,height:130, borderWidth: 1,borderColor: "lightgrey",borderRadius: 10,margin:5,zIndex:1}} onPress={() => navigation.navigate('Root',
							   {screen:'Port Service', params: {category: "Holds Cleaning",clickedport:port_name }}
							   )}>
									<View style={styles.services} ><Icon name="layers" size={35} color="black" /><Text style={{fontSize: 15,padding:3,color:'#146eb4'}}  numberOfLines={2} >Holds Cleaning</Text></View>
							</TouchableOpacity>
						</ScrollView>
				</SafeAreaView>);
		   }			
		}
		
		
	
		
	render() {
		var array,defaultarray=null;
		const { ports } = this.state;
		array = this.props.childName;		
		var filtertype = this.props.filtertype;		
		defaultarray=null;
		if(filtertype=='default'){
			defaultarray	=	ports;
			filtertype = 'companies';
			array = 'companies';
		}else{
			if(global.global_filter_data_pdas!=null){
				defaultarray	=	global.global_filter_data_pdas;
			}else{
				defaultarray	=	ports;
			}
		}
		
		var dataSourceCords, setDataSourceCords = null;
		return (
		  <View style={{width,padding:0,position:'absolute',bottom:height-(height/1.06),left:0,zIndex:5,flex:1,backgroundColor:'transparent'}}>
			  <SafeAreaView style={styles.container}>	
					{(width>748)?<TouchableOpacity
							style={{alignItems: 'flex-start',marginTop:35}}
							onPress={this.leftArrow}>
							<Icon name="arrow-left" size={35} color="black" />
					</TouchableOpacity>			  :null}
						<ScrollView style={{backgroundColor:'transparent'}} pagingEnabled={true} horizontal={true} showsHorizontalScrollIndicator={false}  ref="scrollView" onContentSizeChange={(w, h) => this.setState({scrollViewWidth:width})} scrollEnabled={true} onScroll={this._handleScroll} scrollEventThrottle={15}>
							{defaultarray.map((prop, key) => {
								return (
								   <View style={{backgroundColor:'#fff',color:'#000',width:width/1.2,height:'auto', borderWidth: 1,borderColor: "lightgrey",borderRadius: 10,margin:5,zIndex:1,padding:10}} key={key} >
									   {(width<748)?
									   <View>
											<View style={{flex:1,width:width/1.2,height:20}}>
												<View style={styles.elements}>
													<TouchableOpacity underlayColor='#fff' onPress={() => this.props.navigation.navigate('Root', {screen: 'Port Information', params: {port_id: prop.port_id,port_name: prop.port_name}})} ><Text style={{fontSize: 15,fontWeight:'bold',paddingRight:5,color:'#146eb4',textDecorationLine: 'underline',}}  numberOfLines={1} >{prop.port_name} </Text></TouchableOpacity>
												</View>
												{/*<View style={styles.elements}>
													<TouchableOpacity underlayColor='#fff' onPress={() => this.setglobalvalues(prop.marker,prop.port_name,prop.dwt.toLocaleString(),prop.pda.toLocaleString(),prop.port_id,prop.country_id,this.props.navigation)}><Text style={{fontSize: 15,paddingRight:5,color:'#000',fontWeight:'bold',textDecorationLine: 'underline'}}  numberOfLines={1} >Vessel DWT {prop.dwt.toLocaleString()} </Text></TouchableOpacity>
												</View>*/}
											</View>
											<View style={{flex:1}}>
												<View style={styles.elements}>
													<TouchableOpacity onPress={() => this.setglobalvalues(prop.marker,prop.port_name,prop.dwt.toLocaleString(),prop.pda.toLocaleString(),prop.port_id,prop.country_id,this.props.navigation,prop.port_expenses_total,prop.port_expenses,prop.cargo_expenses,prop.other_expenses,prop.total_days,prop.local_currency)}><Text style={{fontSize: 15,paddingRight:5,color:'#f90',fontWeight:'bold',textDecorationLine: 'underline'}}  numberOfLines={1} >Port Charges {prop.pda.toLocaleString()} USD</Text></TouchableOpacity>
												</View>
											</View>
											<View style={{flex:1}}><TopBar navigation={this.props.navigation} port_name={prop.port_name} port_id={prop.port_id} country_id={prop.country_id} /></View>
										</View>	:
										<View>
											<View style={{flex:1,width:width/1.2,height:20,flexDirection:'row'}}>
												<View style={styles.elements}>
													<TouchableOpacity underlayColor='#fff' onPress={() => this.props.navigation.navigate('Root', {screen: 'Port Information', params: {port_id: prop.port_id,port_name: prop.port_name}})} ><Text style={{fontSize: 20,fontWeight:'bold',paddingRight:5,color:'#146eb4',textDecorationLine: 'underline',}}  numberOfLines={1} >{prop.port_name} </Text></TouchableOpacity>
												</View>
												{/*<View style={styles.elements}>
													<TouchableOpacity underlayColor='#fff' ><Text style={{fontSize: 20,paddingRight:5,color:'#000',fontWeight:'bold',textDecorationLine: 'underline'}}  numberOfLines={1} >Vessel DWT {prop.dwt.toLocaleString()} </Text></TouchableOpacity>
												</View>*/}
												<View style={{marginBottom:15,marginLeft:5,height: 31,padding:0}}>
													<TouchableOpacity onPress={() => this.setglobalvalues(prop.marker,prop.port_name,prop.dwt.toLocaleString(),prop.pda.toLocaleString(),prop.port_id,prop.country_id,this.props.navigation,prop.port_expenses_total,prop.port_expenses,prop.cargo_expenses,prop.other_expenses,prop.total_days,prop.local_currency)} ><Text style={{fontSize: 20,paddingRight:5,color:'#f90',fontWeight:'bold',textDecorationLine: 'underline'}}  numberOfLines={1} >Port Charges {prop.pda.toLocaleString()} USD</Text></TouchableOpacity>
												</View>
											</View>
											<View>
												<View><TopBar navigation={this.props.navigation} port_id={prop.port_id} country_id={prop.country_id} /></View>
										   </View>
									   </View>}
									   {(global.global_port_id==prop.port_id)?<View style={{flex:1,width:(width>748)? width/1.25 : width/1.3}}>{ (array!='default' && array!='pdas') ?  this.renderSubElement(filtertype,this.props.navigation,array,prop.port_name,prop.port_id,prop.country_id,prop.country_name) : null  }</View>:<View style={{flex:1,width:(width>748)? width/1.25 : width/1.3}}>{this.renderSubElement('companies',this.props.navigation,array,prop.port_name,prop.port_id,prop.country_id,prop.country_name)}</View>}
									</View>
								 );
							})}
						</ScrollView>
						{(width>748)?<TouchableOpacity
							style={{alignItems: 'flex-start',marginTop:35}}
							onPress={this.rightArrow}>
							<Icon name="arrow-right" size={35} color="black" />
						</TouchableOpacity>:null}
				</SafeAreaView>
		  </View>
		);
	}
}

const styles = StyleSheet.create({
 container: {
    zIndex:10,flex:1,flexDirection: 'row',backgroundColor:'transparent'
  },
  scrollView: {
    width
  },
  tinyLogo: {
    width: 100,
    height: 100,
  },
  elements:{
	borderRightWidth: (width>748)?1:0,
	borderColor: (width>748)?'lightgrey':null,
	marginBottom:15,padding:0
  },
  services:{
	  flex:1,justifyContent: 'center', alignItems: 'center'
  },
  button:{
    backgroundColor:'#146eb4',
	borderRadius:10,
    borderWidth: 1,
    borderColor: '#fff',
	padding:10,
	margin:1,
	fontSize: 15,height: 38,justifyContent: 'center', alignItems: 'center'
  },
});