import React, { memo } from 'react';
import { Image, StyleSheet } from 'react-native';

const Logo = () => (
  <Image key={12} source={require('../assets/PortDALogoNew2020.png')} style={styles.image} />
);

const styles = StyleSheet.create({
  image: {
    width: 195,
    height: 68,
	marginBottom: 12,
  },
});

export default memo(Logo);