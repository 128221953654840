import React, { Component } from 'react';
import {
	Platform,
	Text,
	View,
	StyleSheet,
	ScrollView,
	Image,
	TouchableOpacity,
	FlatList,Dimensions,
	Button,ActivityIndicator
} from 'react-native';
import { Input } from 'react-native-elements';
import Icon from 'react-native-vector-icons/Feather';
import TopBar from './components/TopBar';
import AsyncStorage from '@react-native-community/async-storage';
const { width, height } = Dimensions.get('window');
import * as global from './components/global';
import PortSlider from './components/PortSlider';
var call = true;
class PostsScreen extends Component {
  constructor(props) {
     super(props);
     this.state = {
       data: [],
       isLoading: true,
       page:1,
       comment:"",
       likes : 0,
       liked:false,
       showloader:true
     };
   }
   
   getallposts(){
		fetch('https://app.portda.com/api/get-posts/'+global.global_major_port_id+'?_limit=5&_page='+this.state.page, {
		  method: 'POST',
		  headers: {
				'Content-Type'  : 'application/json',
				'PortDA-Allowed-Users': 'PortDA_Team',
				'Authorization' : 'Basic YWRtaW5AcG9ydGRhLmNvbTo1MGNjYWQxMjE3NTI4NDEyNzMwMDdlMTUwMTE3YWViMjI3OTVmMDU4MzllYTg3N2M0NDcxMTc3MTUwYWZlNzlk'
		  },
		})
		.then((response) => response.json())
		.then((json) => {
			call=false;
		  this.setState({data: json.posts,showloader:false});
		})
		.catch((error) => console.error(error))
		.finally(() => {
		  this.setState({isLoading: false});
		});
   }

	/*componentDidMount(){
		this.getallposts();
	}
  */
  	functionCall(key){
	    // alert(key);
	   const {comment} = this.state;
	   AsyncStorage.multiGet(['user']).then((Userdata) => {
          let user = Userdata[0][1];
          user = JSON.parse(user);
          console.log(user);
          let userId = user.id;
          let postId = key;

        fetch('https://app.portda.com/api/post-comment',{
        	method:'POST',
        	headers: {
                      'Content-Type'  : 'application/json',
                      'PortDA-Allowed-Users': 'PortDA_Team',
                      'Authorization' : 'Basic YWRtaW5AcG9ydGRhLmNvbTo1MGNjYWQxMjE3NTI4NDEyNzMwMDdlMTUwMTE3YWViMjI3OTVmMDU4MzllYTg3N2M0NDcxMTc3MTUwYWZlNzlk'
                    },
            body:JSON.stringify({
            	postId:postId,
            	userId:userId,
            	comment:comment
            })
        }).then((response)=>response.json())
          .then((responseJson)=>{
            console.log(JSON.stringify(responseJson));
            alert(JSON.stringify(responseJson));
            this.setState({comment:" "});

          })
          .catch((error)=>{
            console.error(error);
          });
       });

	}
	likeapost(key){
		// this.setState({liked:!liked});
		AsyncStorage.multiGet(['user']).then((Userdata) => {
          let user = Userdata[0][1];
          user = JSON.parse(user);
          console.log(user);
          let userId = user.id;
          let postId = key;

        fetch('https://app.portda.com/api/like-post',{
        	method:'POST',
        	headers: {
                      'Content-Type'  : 'application/json',
                      'PortDA-Allowed-Users': 'PortDA_Team',
                      'Authorization' : 'Basic YWRtaW5AcG9ydGRhLmNvbTo1MGNjYWQxMjE3NTI4NDEyNzMwMDdlMTUwMTE3YWViMjI3OTVmMDU4MzllYTg3N2M0NDcxMTc3MTUwYWZlNzlk'
                    },
            body:JSON.stringify({
            	postId:postId,
            	userId:userId
            })
        }).then((response)=>response.json())
          .then((responseJson)=>{
            console.log(JSON.stringify(responseJson));
            alert(JSON.stringify(responseJson));

          })
          .catch((error)=>{
            console.error(error);
          });

      });
	}
  	handleLoadMore = () => {
	  /*this.setState({
	    page:this.state.page+1,
	  },()=>{
	    this.componentDidMount();
	  });*/
	}
	
  render() {
	  
	  
    const { data, isLoading , pressed, comment , liked,	showloader } = this.state;
    // const LikeIcon = this.state.liked ? <Icon name="thumbs-up" color="blue" size={25} /> : < Icon name="thumbs-up" color="blue" size={25}/>;
	 if(call==true){
		this.getallposts();
	 }
	
	call=true;
			
	/* Functional Component */
    function LeftComponent(){
		if(width>768){
			return (<View style={styles.leftview}><Text>Left side text</Text></View>);
		}else{
		  return (null);
		}
	}
	
	/* Functional Component */
    function RightComponent(){
		if(width>768){
			return (<View style={styles.leftview}><Text>Right side text</Text></View>);
		}else{
		  return (null);
		}
	}
 
    return (
	
      <View style={styles.container}>
		{(showloader==false) ?
			<FlatList
			  data={data}
			  renderItem={({ item }) => (
				<View
				  style={{
					marginBottom: 10,
					backgroundColor: 'white',borderBottomWidth: 2,borderColor: "lightgrey",borderRadius: 5
				  }}>
				  <View
					style={styles.profileInfo}>
					<View>
					  <Image
						style={styles.tinyLogo}
						source={require('./assets/PortDALogoNew2020.png')}
					  />
					</View>
					<View style={{ flex: 9, margin: 15 }}>
					  <Text style={{fontWeight: 'bold', fontSize: 15}}>PortDA</Text><Text style={styles.text}>{item.created}</Text>
					</View>
				  </View>
					<View style={styles.postDescription}>
						{/*<Text style={styles.texttitle}>{item.title}</Text>*/}
						<Text style={styles.text}>{item.post}</Text>
					</View>
				  <View style={{justifyContent:'center',flex:1}}>
					<Image
					  style={{width: width, height:(width>768)? height/1.5 : height/2.5, resizeMode:'contain'}}
					  source={{uri:"https://app.portda.com"+item.p.file}}
					/>
				  </View>
				  {/*<View
					style={{
					  flex: 1,
					  flexDirection: 'row',
					  width: '100%',
					  padding: 5,
					}}>
					<TouchableOpacity key={item.id} style={styles.icon} onPress={this.likeapost.bind(this,item.id)}>
					  <Icon name="thumbs-up" color="black" size={25} />
					  <Text style={{ margin: 5 }}>Like</Text>
					</TouchableOpacity>
					<TouchableOpacity id={item.id} style={styles.icon} onPress={() => {this.setState({pressed:!pressed})}}>
					  <Icon name="message-square" size={25} />
					  <Text style={{ margin: 5 }}>Comment</Text>
					</TouchableOpacity>
					<TouchableOpacity style={styles.icon}>
					  <Icon name="share" size={25} />
					  <Text style={{ margin: 5 }}>Share</Text>
					</TouchableOpacity>
				  </View>
				  <View key={item.id} style={{display:this.state.pressed?'block':'none'}}>
					<Input style={{ padding: '5px',outlineColor:"#fff"}} placeholder="Comment this post" onChangeText={comment => this.setState({comment})}/>
					<Button title="Post Comment" onPress={this.functionCall.bind(this,item.id)} />
				  </View>*/}
				</View>
			  )}
			  keyExtractor={({ id }, index) => id}
			  onEndReached={this.handleLoadMore}
			  onEndThreshold={0}
			  />	:
		  <View style={{flex: 1,textAlign: 'center',alignItems: 'center', justifyContent: 'center'}}><ActivityIndicator size="large" color="#146eb4"  /></View>	}
      </View>
    );
  }
}

const styles = StyleSheet.create({
  maincontainer: {
    flex: 1,
    width: '100%',
    height: '100%',backgroundColor:'#fff'
  },
  container: {
    flex: 1,
    width: '100%',
    height: '100%',
	flexDirection: 'row',marginTop:0,backgroundColor:'#fff',padding:10
  },
  profileInfo: {
    flex: 2,
    flexDirection: 'row',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
  },
  tinyLogo: {
    width: 75,
    height: 75,
	resizeMode: 'contain',
  },
  icon: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row',
  },
  headerText: {
    fontSize: 20,
    textAlign: 'center',
    flex: 1,
    color: '#fff',
  },
  text: {
    fontSize: 15,textAlign: 'left',lineHeight:25
  },
  texttitle: {
	fontSize: 25,textAlign: 'center', textDecorationLine: 'underline',fontWeight: 'bold',justifyContent: 'center',alignItems: 'center',width:'100%',marginBottom:5
  },
  postDescription: {
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
	textAlign: 'left',
    padding: 11
  },
  leftview: {
	justifyContent: 'center',
    alignItems: 'center',
    width: '25%',
	height: '100%', backgroundColor: '#fff', margin: 4,borderWidth: 1,borderColor: "lightgrey",borderRadius: 5,
  },
  rightview: {
	justifyContent: 'center',
    alignItems: 'center',
    width: '25%',height: '100%', backgroundColor: '#fff', margin: 4, borderWidth: 1,borderColor: "lightgrey",borderRadius: 5,
  },
  alttext: {
	fontSize: 12,
	color: 'lightgrey'
  }
});


export default PostsScreen;
