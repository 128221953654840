import * as React from 'react';
import { Platform,StyleSheet, Button, View,Picker, SafeAreaView, Text, Alert,Keyboard,Dimensions,ScrollView,TouchableOpacity,TouchableHighlight,TextInput,ActivityIndicator} from 'react-native';
import { Input } from 'react-native-elements';
import Icons from 'react-native-vector-icons/FontAwesome';
import * as global from './global';
import { useIsDrawerOpen } from '@react-navigation/drawer';
import HomeScreen from '../HomeScreen.js';
const { width, height } = Dimensions.get('window');
import { useNavigation } from '@react-navigation/native';
const date = (new Date().getDate()).toString().padStart(2, "0");
const month = (new Date().getMonth()+1).toString().padStart(2, "0"); 
const year = new Date().getFullYear();
let is_it_inside_comparedpdaSubmit	=	false;
var floors = [];
var counter=0;
var tmp_port=0;
class PdaForm extends React.Component {
	constructor(props){
      super(props)
      this.state = {
        vessel_name:"MV TBN",
        dwt:"10000",
		expected_days_at_berth_pda:"3",
		grt:"7000",
		nrt:"4000",
		loa:"107",
        vessel_volume:"21000",
        no_of_cranes:"1",
        vessel_eta:year + '-' + month + '-' + date,
        cargo_type:'General Cargo',
        cargo_quantity:"9000",
        term:'d_f_o',
        user_email:'youremail@test.com',
        token:"1281f476084c8ce42331d6f942e9c75e0d978c24590a9bd53230f112930fa2b6",
        recalculate:1,
        mode_of_submit:2,
        main_flag:2,
        compared:0,
        portErr:false,
		selectedValue:'d_f_o',
        show:false,
		compared_pdas: [],
		global_filter_data_pdas: [],
		fields_error:false,
		vessel_err:false,
		dwt_err:false,
		expected_days_at_berth_pda_err:false,
		grt_err:false,
		nrt_err:false,
		loa_err:false,
		vessel_volume_err:false,
		cargo_type_err:false,
		cargo_quantity_err:false,
		user_email_err:false,
		vessel_volume_show:false,
		showbutton:true
      };
    }
	
	pdaSubmit = (navigation) => {
			this.setState({showbutton:false});
			console.log("step 3");		
			const{vessel_name,dwt,expected_days_at_berth_pda,grt,nrt,loa,vessel_volume,vessel_eta,no_of_cranes,cargo_type,cargo_quantity,term,user_email,token,recalculate,mode_of_submit,main_flag,compared} = this.state;
			
			if(vessel_name=='' || expected_days_at_berth_pda=='' || dwt=='' || grt=='' || vessel_volume=='' || nrt=='' || loa=='' || cargo_type=='' || cargo_quantity=='' || user_email==''){
				if(dwt==''){
					this.setState({dwt_err:true});
				}
				if(grt==''){
					this.setState({grt_err:true});
				}
				if(nrt==''){
					this.setState({nrt_err:true});
				}
				if(loa==''){
					this.setState({loa_err:true});
				}
				if(expected_days_at_berth_pda==''){
					this.setState({expected_days_at_berth_pda_err:true});
				}
				if(cargo_quantity==''){
					this.setState({cargo_quantity_err:true});
				}
				if(vessel_volume==''){
					this.setState({vessel_volume_err:true});
				}
				if(vessel_name==''){
					this.setState({vessel_err:true});
				}
				if(cargo_type==''){
					this.setState({cargo_type_err:true});
				}
				if(user_email==''){
					this.setState({user_email_err:true});
				}
				this.setState({showbutton:true});
				return;
			}else{
				if(dwt!=''){
					this.setState({dwt_err:false});
				}
				if(grt!=''){
					this.setState({grt_err:false});
				}
				if(nrt!=''){
					this.setState({nrt_err:false});
				}
				if(loa!=''){
					this.setState({loa_err:false});
				}
				if(expected_days_at_berth_pda!=''){
					this.setState({expected_days_at_berth_pda_err:false});
				}
				if(cargo_quantity!=''){
					this.setState({cargo_quantity_err:false});
				}
				if(vessel_volume!=''){
					this.setState({vessel_volume_err:false});
				}
				if(vessel_name!=''){
					this.setState({vessel_name_err:false});
				}
				if(cargo_type!=''){
					this.setState({cargo_type_err:false});
				}
				if(user_email!=''){
					let reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
					if (reg.test(user_email) === false) {
						this.setState({user_email_err:true});
						this.setState({showbutton:true});
						return;
					}else {
						this.setState({user_email_err:false});
					}
				}
				this.setState({showbutton:false});
			}
			
			
			
			fetch('https://app.portda.com/api/calculate',{
				  method:'post',
				  headers: {
								  Accept  : 'application/json',
								  'Content-Type'  : 'application/json',
								  'PortDA-Allowed-Users': 'PortDA_Team',
								  'Authorization' : 'Basic YWRtaW5AcG9ydGRhLmNvbTo1MGNjYWQxMjE3NTI4NDEyNzMwMDdlMTUwMTE3YWViMjI3OTVmMDU4MzllYTg3N2M0NDcxMTc3MTUwYWZlNzlk'
							},
				  body:JSON.stringify({
					countries:global.global_country_id,
					port:global.global_major_port_id,
					operation: 0,
					vessel_name:vessel_name,
					dwt:dwt,
					expected_days_at_berth_pda:expected_days_at_berth_pda,
					grt:grt,
					nrt:nrt,
					loa:loa,
					vessel_volume:vessel_volume,
					vessel_eta:vessel_eta,
					no_of_cranes:no_of_cranes,
					cargo_type:cargo_type,
					cargo_quantity:cargo_quantity,
					term:term,
					user_email:user_email,
					token:token,
					recalculate:recalculate,
					mode_of_submit:mode_of_submit,
					main_flag:main_flag,
					compared:compared,
					current_date:'2020-08-13 10:42:33',
					client_ip:'192.230.97.5',
					userid:254
				  })
		})		
		.then((response)=>response.json())
        .then((responseJson)=>{
			console.log("step 4");	
			if(Platform.OS=='web'){
				floors = [];
				floors.push({
							marker: responseJson.marker,
							port_id: responseJson.port_id,
							port_name: responseJson.port_name,
							country_name: responseJson.country_name,
							country_id: responseJson.country_id,
							dwt: dwt,
							pda: responseJson.grand_total_usd,
							port_expenses: responseJson.port_expenses,
							port_expenses_total: responseJson.port_expenses_total,
							cargo_expenses: responseJson.cargo_expenses,
							other_expenses: responseJson.other_expenses,
							total_days: responseJson.total_days,
							local_currency: responseJson.currency
					});
				global.gmarkerspopup['marker_'+global.global_major_port_id].setHTML('<div style="margin:0;background: #fff;color: #000;font-size: 15px;text-align: left !important; width: 210px !important; padding: 5px !important; line-height: 1.5 !important;">'+global.global_port_name+' <br/>Port Charges - '+responseJson.grand_total_usd+' USD </div>');                    
			}else{
				if(responseJson){
					global.global_major_port_id	=	global.global_port_id;
					global.pdaamount			=	responseJson.grand_total_usd;
				}
			}
        })
        .catch((error)=>{
          console.error("apicall"+error);
        })
		.finally(() => 
			/* NOW GET THE COMPARED PDA'S*/
			this.comparedpdaSubmit(navigation));	
    }
	
	
	comparedpdaSubmit=(navigation)=>{
		const{vessel_name,dwt,expected_days_at_berth_pda,grt,nrt,loa,vessel_volume,vessel_eta,no_of_cranes,cargo_type,cargo_quantity,term,user_email,token,recalculate,mode_of_submit,main_flag,compared} = this.state;
		
					
		/*CALL API TO GET THE OTHER PORTS OF THIS COUNTRY*/
		fetch('https://app.portda.com/api/getotherports',{
				  method:'post',
				  headers: {
								  'Content-Type'  : 'application/json',
								  'PortDA-Allowed-Users': 'PortDA_Team',
								  'Authorization' : 'Basic YWRtaW5AcG9ydGRhLmNvbTo1MGNjYWQxMjE3NTI4NDEyNzMwMDdlMTUwMTE3YWViMjI3OTVmMDU4MzllYTg3N2M0NDcxMTc3MTUwYWZlNzlk'
							},
				  body:JSON.stringify({
					countries:global.global_country_id,
					port:global.global_major_port_id,
				  })
		})
		.then((response)=>response.json())
        .then((response_in_Json)=>{
			if(response_in_Json.otherports.length>0){
				for(let i=0;i<response_in_Json.otherports.length;i++) {
					global.global_port_id	=	response_in_Json.otherports[i].port_id;
					global.global_port_name	=	response_in_Json.otherports[i].port_name;
					/* NOW CALL THE PDASUBMIT AGAIN*/
					fetch('https://app.portda.com/api/calculate',{
					  method:'post',
					  headers: {
									  'Content-Type'  : 'application/json',
									  'PortDA-Allowed-Users': 'PortDA_Team',
									  'Authorization' : 'Basic YWRtaW5AcG9ydGRhLmNvbTo1MGNjYWQxMjE3NTI4NDEyNzMwMDdlMTUwMTE3YWViMjI3OTVmMDU4MzllYTg3N2M0NDcxMTc3MTUwYWZlNzlk'
								},
					  body:JSON.stringify({
						countries:global.global_country_id,
						port:global.global_port_id,
						operation: 0,
						vessel_name:vessel_name,
						dwt:dwt,
						expected_days_at_berth_pda:expected_days_at_berth_pda,
						grt:grt,
						nrt:nrt,
						loa:loa,
						vessel_volume:vessel_volume,
						vessel_eta:vessel_eta,
						no_of_cranes:no_of_cranes,
						cargo_type:cargo_type,
						cargo_quantity:cargo_quantity,
						term:term,
						user_email:user_email,
						token:token,
						recalculate:recalculate,
						mode_of_submit:mode_of_submit,
						main_flag:0,
						compared:2,
						current_date:'2020-08-13 10:42:33',
						client_ip:'192.230.97.5',
						userid:254,
					  })
					})
					
					.then((response)=>response.json())
					.then((responseJson)=>{
						floors.push({
								marker: responseJson.marker,
								port_id: responseJson.port_id,
								country_name: responseJson.country_name,
								country_id: responseJson.country_id,
								dwt: dwt,
								port_name: responseJson.port_name,
								pda: responseJson.grand_total_usd,
								port_expenses: responseJson.port_expenses,
								port_expenses_total: responseJson.port_expenses_total,
								cargo_expenses: responseJson.cargo_expenses,
								other_expenses: responseJson.other_expenses,
								total_days: responseJson.total_days,
								local_currency: responseJson.currency
						});
						global.global_filter_data_pdas	=	floors;
					})
					.catch((error)=>{
					  console.error("Here1".error);
					})
					.finally(() => this.showresults(navigation,floors));
				}
			}else{
				this.showresults(navigation,floors);
			}
		})
        .catch((error)=>{
          console.error("Here3".error);
        });	
	}
	
	
	
	setvalue= (itemValue) => {
		const{vessel_name,dwt,expected_days_at_berth_pda,grt,nrt,loa,vessel_volume,vessel_eta,no_of_cranes,cargo_type,cargo_quantity,term,user_email,token,recalculate,mode_of_submit,main_flag,compared} = this.state;
			
		this.setState({term: itemValue,selectedValue:itemValue});
	}
	
	close = (navigation) => {
		navigation.closeDrawer();
	}


	showresults=(navigation,files)=>{
		this.setState({showbutton:true});
		global.global_filter_data_pdas	=	files;
		navigation.navigate('HomeScreen', {
            pdas: files,
		});
	}
	
	isNumeric(num){
	  return !isNaN(num);
	}
	
	callapi(dwt){
		fetch('https://app.portda.com/api/getinputs',{
				  method:'post',
				  headers: {
								  'Content-Type'  : 'application/json',
								  'PortDA-Allowed-Users': 'PortDA_Team',
								  'Authorization' : 'Basic YWRtaW5AcG9ydGRhLmNvbTo1MGNjYWQxMjE3NTI4NDEyNzMwMDdlMTUwMTE3YWViMjI3OTVmMDU4MzllYTg3N2M0NDcxMTc3MTUwYWZlNzlk'
							},
				  body:JSON.stringify({
					dwt:dwt,
					port:global.global_major_port_id
				  })
		})
		.then((response)=>response.json())
        .then((response_in_Json)=>{			
			this.setState({grt: response_in_Json.ai_array.grt,nrt: response_in_Json.ai_array.nrt,loa: response_in_Json.ai_array.loa,cargo_quantity: response_in_Json.ai_array.cargo_quantity,});
			if(response_in_Json.ai_array.vessel_volume>0){
				this.setState({vessel_volume: response_in_Json.ai_array.vessel_volume});
			}
		})
		.catch((error)=>{
		  console.error("Here1".error);
		});
	}
	
	validate(input,value){
		const{vessel_name,dwt,expected_days_at_berth_pda,grt,nrt,loa,vessel_volume,vessel_eta,no_of_cranes,cargo_type,cargo_quantity,term,user_email,token,recalculate,mode_of_submit,main_flag,compared} = this.state;
		this.setState({user_email:''});
		if(input=='dwt'	||	input=='grt'	||	input=='nrt'	||	input=='loa'	||	input=='expected_days_at_berth_pda'	||	input=='cargo_quantity' || input=='vessel_volume'	){
			if(this.isNumeric(value)){
				if(input=='dwt'){
					this.setState({dwt:value});
					this.setState({dwt_err:false});
					/* CALL API FOR AI VALUES*/
					this.callapi(value);
				}else if(input=='grt'){
					this.setState({grt:value});
					this.setState({grt_err:false});
				}else if(input=='nrt'){
					this.setState({nrt:value});
					this.setState({nrt_err:false});
				}else if(input=='loa'){
					this.setState({loa:value});
					this.setState({loa_err:false});
				}else if(input=='expected_days_at_berth_pda'){
					this.setState({expected_days_at_berth_pda:value});
					this.setState({expected_days_at_berth_pda_err:false});
				}else if(input=='cargo_quantity'){
					this.setState({cargo_quantity:value});
					this.setState({cargo_quantity_err:false});
				}else if(input=='vessel_volume'){
					this.setState({vessel_volume:value});
					this.setState({vessel_volume_err:false});
				}else if(input=='user_email'){
					let reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
					if (reg.test(user_email) === false) {
						this.setState({user_email_err:true});
						this.setState({showbutton:true});
					}else {
						this.setState({user_email_err:false});
					}
				}
			}else{
				this.setState({input:0});
			}
		}	
	}
	
	callonce(){
		fetch('https://app.portda.com/api/getvesselcolumeflag',{
				  method:'post',
				  headers: {
								  'Content-Type'  : 'application/json',
								  'PortDA-Allowed-Users': 'PortDA_Team',
								  'Authorization' : 'Basic YWRtaW5AcG9ydGRhLmNvbTo1MGNjYWQxMjE3NTI4NDEyNzMwMDdlMTUwMTE3YWViMjI3OTVmMDU4MzllYTg3N2M0NDcxMTc3MTUwYWZlNzlk'
							},
				  body:JSON.stringify({
					port:global.global_major_port_id,
				  })
		})
		.then((response)=>response.json())
        .then((response_in_Json)=>{
			if(response_in_Json.status=='yes'){
				this.setState({vessel_volume_show: true});
			}else{
				this.setState({vessel_volume_show: false});
			}
		})
		.catch((error)=>{
		  console.error("Here1".error);
		});
		counter = 1;
	}
	
	render() {
		const {vessel_volume_show,vessel_volume_err,user_email_err,cargo_quantity_err,cargo_type_err,loa_err,nrt_err,grt_err,expected_days_at_berth_pda_err,dwt_err,vessel_err,fields_error,selectedValue,vessel_eta,show,vessel_name,dwt,expected_days_at_berth_pda,grt,nrt,loa,vessel_volume,cargo_type,cargo_quantity,user_email} = this.state;
		const {navigation}	=	this.props;
		if(counter==0 && tmp_port	!=	global.global_major_port_id){
			this.callonce();
			tmp_port	=	global.global_major_port_id;
		}
		counter = 0;
		
		return (<SafeAreaView style={{width:'100%',flex:1}}>
			<ScrollView style={styles.scrollView}>
				<View style={styles.center}>
					<View style={{flexDirection: 'row',flex:1}}>
						<Text style={styles.title_new}>Get Port Charges Globally In Few Seconds </Text>
						<TouchableOpacity style={{ zIndex:1,backgroundColor:'#f90'}}  onPress={() => this.close(navigation)}>
							<Icons name="close" size={30} color="#fff" style={{marginTop:0}} />
						</TouchableOpacity></View>
					</View>
				<View style={styles.center}>
					<TextInput style={styles.inputstyle} placeholder='Vessel Name ex: MV EXAMPLE' value={vessel_name} onChangeText = {vessel_name =>this.setState({vessel_name})}/>
					<Text style={styles.placeholder}>Vessel Name</Text>
				</View>
				{(vessel_err)?<View style={styles.center}><Text style={styles.error}>*Vessel Name Invalid / empty</Text></View>:null}				
				<View style={styles.center}>
					<TextInput style={styles.inputstyle} maxLength={10} keyboardType="number-pad" placeholder='Vessel DWT' value={dwt} onChangeText = {dwt => this.validate('dwt',dwt)}/>
					<Text style={styles.placeholder}>DWT</Text>
				</View>
				{(dwt_err)?<View style={styles.center}><Text style={styles.error}>*Vessel DWT Invalid / empty</Text></View>:null}	
				<View style={styles.center}>
					<TextInput style={styles.inputstyle}  keyboardType="number-pad" placeholder='Expected Number Of Days At Berth'  value={expected_days_at_berth_pda} onChangeText = {expected_days_at_berth_pda =>this.validate('expected_days_at_berth_pda',expected_days_at_berth_pda)}/>
					<Text style={styles.placeholder}>DAYS</Text>
				</View>
				{(expected_days_at_berth_pda_err)?<View style={styles.center}><Text style={styles.error}>*Expected Number Of Days At Berth Invalid / empty</Text></View>:null}
				<View style={styles.center}>
					<TextInput style={styles.inputstyle}  keyboardType="number-pad" placeholder='Vessel GRT' value={grt} onChangeText = {grt => this.validate('grt',grt)}/>
					<Text style={styles.placeholder}>GRT</Text>
				</View>
				{(grt_err)?<View style={styles.center}><Text style={styles.error}>*Vessel GRT Invalid / empty</Text></View>:null}				
				<View style={styles.center}>
					<TextInput style={styles.inputstyle}  keyboardType="number-pad" placeholder='Vessel NRT' value={nrt} onChangeText = {nrt => this.validate('nrt',nrt)}/>
					<Text style={styles.placeholder}>NRT</Text>
				</View>
				{(nrt_err)?<View style={styles.center}><Text style={styles.error}>*Vessel NRT Invalid / empty</Text></View>:null}
				<View style={styles.center}>
					<TextInput style={styles.inputstyle}  keyboardType="number-pad" placeholder='Vessel LOA' value={loa} onChangeText = {loa => this.validate('loa',loa)}/>
					<Text style={styles.placeholder}>LOA</Text>
				</View>
				{(loa_err)?<View style={styles.center}><Text style={styles.error}>*Vessel LOA Invalid / empty</Text></View>:null}
				{(vessel_volume_show)?<View style={styles.center}>
					<TextInput style={styles.inputstyle}  keyboardType="number-pad" placeholder='Vessel Volume' value={vessel_volume} onChangeText = {vessel_volume => this.validate('vessel_volume',vessel_volume)}/>
					<Text style={styles.placeholder}>VESSEL VOLUME</Text>
				</View>:null}
				{(vessel_volume_err)?<View style={styles.center}><Text style={styles.error}>*Vessel Volume Invalid / empty</Text></View>:null}
				<View style={styles.center}>
					<TextInput style={styles.inputstyle} placeholder='Cargo Type' value={cargo_type}  onChangeText = {cargo_type => this.setState({cargo_type})}/>
					<Text style={styles.placeholder}>CARGO</Text>
				</View>
				{(cargo_type_err)?<View style={styles.center}><Text style={styles.error}>*Cargo Type Invalid / empty</Text></View>:null}
				<View style={styles.center}>
					<TextInput style={styles.inputstyle}  keyboardType="number-pad" placeholder='Cargo Quantity' value={cargo_quantity}  onChangeText = {cargo_quantity => this.validate('cargo_quantity',cargo_quantity)}/>
					<Text style={styles.placeholder}>QUANTITY</Text>
				</View>
				{(cargo_quantity_err)?<View style={styles.center}><Text style={styles.error}>*Cargo Quantity Invalid / empty</Text></View>:null}
				<View style={{zIndex: 2,width:'100%',marginBottom:10}}>
					<Picker
						selectedValue={selectedValue}
						style={{zIndex: 2,width:'100%',backgroundColor: '#fafafa',height: 50}}
						onValueChange={(itemValue, itemIndex) => this.setvalue(itemValue)}
					  >
						<Picker.Item label="Loading (Free In)" value="l_f_i" />
						<Picker.Item label="Loading (Liner In)" value="l_l_i" />
						<Picker.Item label="Discharging (Free Out)" value="d_f_o" />
						<Picker.Item label="Discharging (Liner Out)" value="d_l_o" />
					</Picker>
				</View>
				<View style={styles.center}>
					<TextInput style={styles.inputstyle} placeholder='Enter Corporate Email' keyboardType="email-address"  value={user_email} onChangeText = {user_email => this.setState({user_email})}/>
					<Text style={styles.placeholder}>EMAIL</Text>
				</View>
				{(user_email_err)?<View style={styles.center}><Text style={styles.error}>*Email Invalid / empty</Text></View>:null}
				{(fields_error)?<View style={styles.center}><Text style={styles.error}>*Please enter all input fields, some fields are missing</Text></View>:null}
				<View style={[styles.center,{padding:5,backgroundColor:'#146eb4'}]}>{(this.state.showbutton) ? <Button title="Calculate" color="#146eb4" onPress={() => this.pdaSubmit(navigation)} style={styles.button} />	:	<ActivityIndicator size="large" color="#fff"  />}</View>
				<View style={[styles.center,{padding:5}]}><Text style={{fontSize:20,fontWeight:'bold',color:'#146eb4'}}>Port Charges - {global.pdaamount} USD</Text></View>
				<View style={styles.center}><Button title="Breakdown" color="#146eb4" onPress={() => global.navigation.navigate('Root',{screen: 'PDA Estimation',params: {pda: global.pdaamount,port_expenses_total: global.port_expenses_total,port_name: global.global_port_name,port_expenses: global.port_expenses,cargo_expenses: global.cargo_expenses,other_expenses: global.other_expenses,total_days: global.total_days,local_currency: global.local_currency }})}   style={styles.button} /></View>
			</ScrollView>
		</SafeAreaView>);
	}
}

const styles = StyleSheet.create({
  center: {
	flex: 1,width: '100%',backgroundColor:'#FFFFFF',justifyContent: 'center',position:'relative'
  },
  placeholder:{
	  position:'absolute',right:10,top:10,color:'#146eb4',fontSize:15
  },
  button:{
    backgroundColor:'#146eb4',flex: 1,justifyContent: 'center',
  },
  inputstyle:{
	  fontSize:15,justifyContent: 'flex-start', alignItems: 'flex-start',padding:10,zIndex: 1,borderBottomWidth: 1,borderColor: "lightgrey",
  },
   scrollView: {
    backgroundColor: '#FFFFFF',height:height+50,flex:1,zIndex: 10
  },
  title_new: {
	backgroundColor: '#F90',
	color: '#fff',
	padding: 5,
	width: '100%',fontSize: 15,justifyContent: 'flex-start',flex:1,paddingTop:5,zIndex:5
  },
  containerStyle:{
	height:70  
  },
  error:{
	  color:'red',fontSize:12,padding:5,justifyContent: 'center', alignItems: 'center'
  }
});


export default PdaForm;