import React, { Component } from 'react';
import {
	Platform,
	Text,
	View,
	StyleSheet,
	ScrollView,SafeAreaView,
	Image,
	TouchableOpacity,
	FlatList,Dimensions
} from 'react-native';
import Icon from 'react-native-vector-icons/Ionicons';
import TopBar from './components/TopBar';
const { width, height } = Dimensions.get('window');

class ListScreen extends Component {
  constructor(props) {
     super(props);
   }
  render() {
    
	var json_port_expenses = this.props.route.params.port_expenses;
	var arr_port_expenses = [];
	var arr_port_expenses_total=0;
	Object.keys(json_port_expenses).forEach(function(key) {
		arr_port_expenses.push(json_port_expenses[key]);
    });
	
	
	var json_cargo_expenses = this.props.route.params.cargo_expenses;
	var arr_cargo_expenses = [];
	if(json_cargo_expenses!=undefined || json_cargo_expenses!=null){
		Object.keys(json_cargo_expenses).forEach(function(key) {
			arr_cargo_expenses.push(json_cargo_expenses[key]);
		});
	}
	
	
	var json_other_expenses = this.props.route.params.other_expenses;
	var arr_other_expenses = [];
	if(json_other_expenses!=undefined || json_other_expenses!=null){
		Object.keys(json_other_expenses).forEach(function(key) {
			arr_other_expenses.push(json_other_expenses[key]);
		});
	}
	
    return (<SafeAreaView style={styles.container}>
					<ScrollView horizontal={false} scrollEnabled={true}>
						<View style={{flex: 1,flexDirection:'row'}}><Text style={styles.heading}>{this.props.route.params.port_name}</Text><Text style={styles.heading}>Expected No. of days at berth - {this.props.route.params.total_days}</Text></View>
						<View><Text style={styles.expenses}>Port Expenses</Text></View>
						<View style={styles.subcontainer}>
							<View style={styles.leftrightcontent}>
								{arr_port_expenses.map((e, i) => 							
									<View style={styles.content} key={i}><Text>{e}</Text></View>
								)}
							</View>
							<View style={styles.leftrightcontent}>
								<View style={styles.content}><Text style={{color:'#146eb4',fontSize:20,}}>{this.props.route.params.port_expenses_total} {this.props.route.params.local_currency}</Text></View>
							</View>
							<View style={styles.leftrightcontent}>
								<View style={styles.content}><Text style={{color:'#146eb4',fontSize:20}}>Total</Text></View>
							</View>
							<View style={styles.leftrightcontent}>
								<View style={styles.content}><Text style={{color:'#146eb4',fontSize:20}}>{this.props.route.params.port_expenses_total}  {this.props.route.params.local_currency}</Text></View>
							</View>
						</View>
						<View><Text style={styles.expenses}>Cargo Expenses</Text></View>
						<View style={styles.subcontainer}>
							<View style={styles.leftrightcontent}>
								{arr_cargo_expenses.map((e, i) => 
									<View style={styles.content} key={i}><Text>{e}</Text></View>
								)}
							</View>
							<View style={styles.leftrightcontent}>
								<View style={styles.content}><Text style={{color:'red',fontSize:20}}>Our Agent will contact you with prices</Text></View>
							</View>
							<View style={styles.leftrightcontent}>
								<View style={styles.content}><Text style={{color:'#146eb4',fontSize:20}}>Total</Text></View>
							</View>
							<View style={styles.leftrightcontent}>
								<View style={styles.content}><Text style={{color:'#146eb4',fontSize:20}}>0 USD</Text></View>
							</View>
						</View>
						<View><Text style={styles.expenses}>Other Expenses</Text></View>
						<View style={styles.subcontainer}>
							<View style={styles.leftrightcontent}>
								{arr_other_expenses.map((e, i) => 
									<View style={styles.content} key={i}><Text>{e}</Text></View>
								)}
							</View>
							<View style={styles.leftrightcontent}>
								<View style={styles.content}><Text style={{color:'red',fontSize:20}}>Our Agent will contact you with prices</Text></View>
							</View>
							<View style={styles.leftrightcontent}>
								<View style={styles.content}><Text style={{color:'#146eb4',fontSize:20}}>Total</Text></View>
							</View>
							<View style={styles.leftrightcontent}>
								<View style={styles.content}><Text style={{color:'#146eb4',fontSize:20}}>0 USD</Text></View>
							</View>
						</View>
						<View><Text style={styles.expenses}>Grand Total</Text></View>
						<View style={styles.subcontainer}>
							<View style={styles.leftrightcontent}>
								<View style={styles.content}><Text style={{color:'#f90',fontSize:20,fontWeight:'bold'}}>Grand Total</Text></View>
							</View>
							<View style={styles.leftrightcontent}>
								<View style={styles.content}><Text style={{color:'#f90',fontSize:20,fontWeight:'bold'}}>{this.props.route.params.port_expenses_total}  {this.props.route.params.local_currency} ({this.props.route.params.pda} USD)</Text></View>
							</View>
						</View>
					</ScrollView>
			</SafeAreaView>);
  }
}

const styles = StyleSheet.create({
   container: {
    flex: 4,
    width,
	backgroundColor: '#fff', position:'absolute',top:0
  },
  subcontainer: {
    flex: 1,flexDirection:'row'
  },
  leftrightcontent:{width: '50%',padding:10,alignItems: 'center'},
  content:{width: '100%',textAlign: 'left',padding:3},
  heading:{
	  fontSize: 15,padding:10,backgroundColor: '#f90', color:'#fff',textAlign: 'center', justifyContent: 'center',alignItems: 'center',width:'50%',height:50
  },
  expenses:{
	  fontSize: 15,padding:5,backgroundColor: '#146eb4',color:'#fff',textAlign: 'center', justifyContent: 'center',alignItems: 'center',width:'100%',
  },
  posts: {
    width: '100%',height: 'auto',flexDirection: 'row',padding:5
  },
  profileInfo: {
    flex: 2,
    flexDirection: 'row',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
  },
  tinyLogo: {
    width: 75,
    height: 75,
	resizeMode: 'contain',
  },
  icon: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row',
  },
  headerText: {
    fontSize: 20,
    textAlign: 'center',
    flex: 1,
    color: '#fff',
  },
  text: {
    fontSize: 12,textAlign: 'left',
  },
  texttitle: {
	fontSize: 15,textAlign: 'center', textDecorationLine: 'underline',fontWeight: 'bold',justifyContent: 'center',alignItems: 'center',width:'100%',marginBottom:5
  },
  postDescription: {
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
	textAlign: 'left',
    padding: 5
  },
  leftview: {
	justifyContent: 'center',
    alignItems: 'center',
    width: '25%',
	height: '100%', backgroundColor: '#fff', margin: 4,borderWidth: 1,borderColor: "lightgrey",borderRadius: 5,
  },
  rightview: {
	justifyContent: 'center',
    alignItems: 'center',
    width: '25%',height: '100%', backgroundColor: '#fff', margin: 4, borderWidth: 1,borderColor: "lightgrey",borderRadius: 5,
  },
  alttext: {
	fontSize: 12,
	color: 'lightgrey'
  }
});

export default ListScreen;
