import React, { Component } from 'react';
import {
	Platform,
	Text,
	View,
	StyleSheet,
	ScrollView,SafeAreaView,
	Image,
	TouchableOpacity,
	FlatList,Dimensions,Button,TextInput,ActivityIndicator 
} from 'react-native';
import Icon from 'react-native-vector-icons/Ionicons';
import TopBar from './components/TopBar';
import * as global from './components/global';
const { width, height } = Dimensions.get('window');

class ServiceScreen extends Component {
  constructor(props) {
     super(props);
	 this.state = {
        email:null,
        name:null,
        enquiry:null,
        name_err:false,
        email_err:false,
        enquiry_err:false,
        show:true,
        message:false,
        isLoading:false,
      }
   }
   
   submitservice = (category,port_name) => {
		const {show,isLoading,message}	=	this.state;
		
		
		const{name,email,enquiry,name_err,email_err,enquiry_err} = this.state;
			
		if(name=='' || name==null){
			this.setState({name_err: true});
			this.setState({email_err: false});	
			this.setState({message: false});
			this.setState({enquiry_err: false});
			return;			
		}else if(email=='' || email==null){
			this.setState({name_err: false});
			this.setState({email_err: true});	
			this.setState({enquiry_err: false});	
			this.setState({message: false});
			return;
		}else if(enquiry=='' || enquiry==null){
			this.setState({name_err: false});
			this.setState({email_err: false});	
			this.setState({enquiry_err: true});
			this.setState({message: false});
			return;
		}else{
			this.setState({name_err: false});	
			this.setState({email_err: false});	
			this.setState({enquiry_err: false});	
			this.setState({show: false,message: false,isLoading: true});
		}
	
		if(name_err==false	&&	email_err==false	&&	enquiry_err==false){
		  fetch('https://app.portda.com/api/submitservice',{
			  method:'post',
			  headers: {
							  'Content-Type'  : 'application/json',
							  'PortDA-Allowed-Users': 'PortDA_Team',
							  'Authorization' : 'Basic YWRtaW5AcG9ydGRhLmNvbTo1MGNjYWQxMjE3NTI4NDEyNzMwMDdlMTUwMTE3YWViMjI3OTVmMDU4MzllYTg3N2M0NDcxMTc3MTUwYWZlNzlk'
						},
			  body:JSON.stringify({
				useremail:email,
				name:name,
				enquiry:enquiry,
				service:category,
				port:port_name
			  })
		  })
		  .then((response)=>response.json())
			.then((responseJson)=>{
				if(responseJson.status=='success'){
						this.setState({show: true,isLoading: false,message:true});
				}else{
					this.setState({invalid_err: true});
				}
				
			})
			.catch((error)=>{
			  console.error(error);
			});
		}
    }
  render() {
    
	var category = this.props.route.params.category;
	var port_name = this.props.route.params.clickedport;
	const{name,email,enquiry,message} = this.state;
	
    return (<View style={styles.container} >
				{(width>748) ? <View style={styles.left} ></View> : null}
				<View style={styles.center} >
					<View style={{justifyContent: 'center',alignItems: 'center',backgroundColor:'lightgreen'}}><Text style={{color:'#fff',fontSize:12,flex: 1,justifyContent: 'center',alignItems: 'center',padding:5}}>Need {category}? Please send enquiry and we will get back to you instantly</Text></View>
					<View style={{ width: (width>748) ? 400 : width,margin: 10,zIndex: 1}}><TextInput style={styles.inputstyle} 
					  placeholder='Enter Company Name'
					onChangeText = {name =>this.setState({name})}
					/></View>
					{ this.state.name_err &&
						<View style={{ width: (width>748) ? 400 : width,margin: 10,zIndex: 1}} ><Text style={styles.error}>*Please enter company name</Text></View>
					}
					<View style={{ width: (width>748) ? 400 : width,margin: 10,zIndex: 1}}><TextInput style={styles.inputstyle} 
					  placeholder='Enter Email' keyboardType='email-address'
					onChangeText = {email =>this.setState({email})}  
					/></View>
					{ this.state.email_err &&
						<View style={{ width: (width>748) ? 400 : width,margin: 10,zIndex: 1}} ><Text style={styles.error}>*Please enter email</Text></View>
					}
					<View style={{ width: (width>748) ? 400 : width,margin: 10,zIndex: 1}} ><TextInput style={styles.inputstyle} 
  placeholder='Your Enquiry'	numberOfLines={10}	multiline={true}
					onChangeText = {enquiry => this.setState({enquiry})} 
					/></View>
					{ this.state.enquiry_err &&
						<View style={{ width: (width>748) ? 400 : width,margin: 10,zIndex: 1}} ><Text style={styles.error}>*Please enter enquiry details</Text></View>
					}
					<View style={{ width: (width>748) ? 400 : width,margin: 10,zIndex: 1,backgroundColor: '#146eb4',zIndex: 1,justifyContent: 'center', alignItems: 'center'}} >
						<TouchableOpacity style={styles.button} underlayColor='#fff' onPress={() => this.submitservice(category,port_name)}>
							{(this.state.show && !this.state.isLoading) ? <Text style={styles.titlestyle}>Send</Text>	:	<ActivityIndicator animating={this.state.isLoading} size="large" color="#fff"  />}
						</TouchableOpacity>
					</View>
					<View style={{ width: (width>748) ? 400 : width,margin: 10,zIndex: 1,justifyContent: 'center', alignItems: 'center',padding:10}} >
						{(this.state.message) ? <Text style={{color:'#000',justifyContent: 'center', alignItems: 'center',fontSize:20}}>Your message has been sent succssfully.</Text> : null}
					</View>
				</View>
				{(width>748) ? <View style={styles.right} ></View> : null }
			</View>);
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
	flexDirection: 'row',
    width: '100%',
    height: '100%',
	backgroundColor:'#FFFFFF',padding:10
  },
  left: {
	width: '30%',
	height: '100%',
  },
  titlestyle:{
	color: 'white' ,justifyContent: 'center', alignItems: 'center',fontSize:20,padding:10
  },
  inputstyle:{
	  fontSize:15,justifyContent: 'flex-start', alignItems: 'flex-start',padding:15,zIndex: 1,borderBottomWidth: 1,borderColor: "lightgrey",
  },
  center: {
	justifyContent: 'center',padding:10,margin:10,
	alignItems: 'center',
	width: (width>748) ? '40%' : '100%',
	height: '100%',
  },
  right: {
	justifyContent: 'center',
	alignItems: 'center',
	width: '30%',
	height: '100%',
  },
  button:{
    backgroundColor:'#146eb4',
	width: '(width>748) ? 400 : width',
  },
  error: {
    color:'red',
	fontSize:12,
  }
});

export default ServiceScreen;
