import React, { Component } from 'react';
import {
	Platform,
	Text,
	View,
	StyleSheet,
	ScrollView,
	Image,
	TouchableOpacity,
	FlatList,Dimensions
} from 'react-native';
import Icon from 'react-native-vector-icons/Feather';
import TopBar from './components/TopBar';
const { width, height } = Dimensions.get('window');
import * as global from './components/global';
import PortSlider from './components/PortSlider'
class SinglePostScreen extends Component {
  constructor(props) {
  	super(props);
    this.state = {
      likes:0
    };
  }
  _increaseValue = () => {
    this.setState((prevState) => {
      return {
        likes: prevState.likes++
      };
    });
  }
  render() {
  	const { likes,liked } = this.state;
    return (
		<View style={styles.maincontainer}>
				  <View>
					<View style={styles.profileInfo}>
					  <View>
						<Image
						  style={styles.tinyLogo}
						  source={require('./assets/PortDAThumbnailNew.png')}
						/>
					  </View>
					  <View style={{ marginTop : 22,marginLeft : 5 }}>
						<Text style={{fontSize: 15,color: 'grey'}}>{this.props.route.params.username}</Text><Text style={styles.alttext}>{this.props.route.params.created}</Text>
					  </View>
					</View>
					<View style={styles.postDescription}>
						{/*<Text style={styles.texttitle}>{this.props.route.params.title}</Text>*/}
						<Text style={styles.text}>{this.props.route.params.post}</Text>
					</View>
					<View>
					  <Image
						style={{width: width, height:(width>768)? height/2 : height/2.5, resizeMode:'contain'}}
						source={'https://app.portda.com/'+this.props.route.params.file}
					  />
					</View>
					{/*<View
					  style={{
						flex: 1,
						flexDirection: 'row',
						width: '100%',
						padding: 5,
					  }}>
					  <TouchableOpacity style={styles.icon} onPress={() => {this.setState({liked:!liked})}}>
						<View>{this.state.liked ? <Icon name="thumbs-up" color="blue" size={25} /> : <Icon name="thumbs-up" size={25} />}</View>
						<Text style={{ margin: 5 }}>{this.state.likes}Like</Text>
					  </TouchableOpacity>
					  <TouchableOpacity style={styles.icon}>
						<Icon name="message-square" size={25} />
						<Text style={{ margin: 5 }}>Comment</Text>
					  </TouchableOpacity>
					  <TouchableOpacity style={styles.icon}>
						<Icon name="share" size={25} />
						<Text style={{ margin: 5 }}>Share</Text>
					  </TouchableOpacity>
					</View>
					</View>*/}
			</View>
		  </View>
    );
  }
}

const styles = StyleSheet.create({
 maincontainer: {
    flex: 1,
    width: '100%',
    height: '100%',backgroundColor:'#fff',padding:10
  },
  container: {
    flex: 1,
    width: '100%',
    height: '100%',
	flexDirection: 'row',marginTop:0,backgroundColor:'#fff'
  },
  
  profileInfo: {
    flex: 2,
    flexDirection: 'row',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
  },
  tinyLogo: {
    width: 75,
    height: 75,
	resizeMode: 'contain',
  },
  icon: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row',
  },
  headerText: {
    fontSize: 20,
    textAlign: 'center',
    flex: 1,
    color: '#fff',
  },
  text: {
    fontSize: 15,textAlign: 'left',lineHeight:25
  },
  texttitle: {
	fontSize: 25,textAlign: 'center', textDecorationLine: 'underline',fontWeight: 'bold',justifyContent: 'center',alignItems: 'center',width:'100%',marginBottom:5
  },
  postDescription: {
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
	textAlign: 'left',
    padding: 11
  },
  leftview: {
	justifyContent: 'center',
    alignItems: 'center',
    width: '25%',
	height: '100%', backgroundColor: '#fff', margin: 4,borderWidth: 1,borderColor: "lightgrey",borderRadius: 5,
  },
  rightview: {
	justifyContent: 'center',
    alignItems: 'center',
    width: '25%',height: '100%', backgroundColor: '#fff', margin: 4, borderWidth: 1,borderColor: "lightgrey",borderRadius: 5,
  },
  alttext: {
	fontSize: 12,
	color: 'lightgrey'
  }
});

export default SinglePostScreen;
