import React, { memo } from 'react';
import {
  AppRegistry,
  Platform,
  StyleSheet,
  Text,
  View,
  ScrollView,
  SafeAreaView,
  Image,
  TouchableOpacity,Dimensions,ActivityIndicator
} from 'react-native';
import { Card, ListItem, Header, Button } from 'react-native-elements';
import SlidingPanel from 'react-native-sliding-panels';
import Icons from 'react-native-vector-icons/AntDesign';
import * as global from './global';
const { width, height } = Dimensions.get('window');

class TopBar extends React.Component {
		constructor(props) {
		  super(props);
			this.state = {
				show: true,
				showfactories: true,
				showcargo: true,
				showvessels: true,
				showposts: true,
				isLoading: true
			};
		}
	
	
	
	
	open = (navigation) => {
		navigation.openDrawer();
	}
	
	
	/* PDA FILTER */
	getallpdas	=	(navigation)	=>	{
		global.global_port_id				=	this.props.port_id;
		navigation.navigate('HomeScreen', {
            pdas: global.global_filter_data_pdas,
			posts: null,
			activevessels: null,
			companies: null,
			factories: null,
          });
	}
	
	/* POSTS FILTER */
	getallposts	=	(navigation)	=>	{
		global.global_port_id				=	this.props.port_id;
		/*if(global.global_filter_data_posts!=undefined){
			navigation.navigate('HomeScreen', {
				posts: global.global_filter_data_posts,
			});
		}*/
		this.setState({showposts: false});
		
			fetch('https://app.portda.com/api/get-posts/'+this.props.port_id, {
			  method: 'POST',
			  headers: {
				'Content-Type'  : 'application/json',
					'PortDA-Allowed-Users': 'PortDA_Team',
					'Authorization' : 'Basic YWRtaW5AcG9ydGRhLmNvbTo1MGNjYWQxMjE3NTI4NDEyNzMwMDdlMTUwMTE3YWViMjI3OTVmMDU4MzllYTg3N2M0NDcxMTc3MTUwYWZlNzlk'
			  },
			})
			.then((response) => response.json())
			.then((json) => {
				this.setState({showposts: true});
			  navigation.navigate('HomeScreen', {
				posts: json.posts,
				pdas: null,
				activevessels: null,
				companies: null,
				factories: null,
			  });
			})
			.catch((error) => console.error(error));
	}
	
	/* POSTS FILTER */
	getallcargos	=	(navigation)	=>	{
		/*if(global.global_filter_data_posts!=undefined){
			navigation.navigate('HomeScreen', {
				posts: global.global_filter_data_posts,
			});
		}*/
		global.global_port_id				=	this.props.port_id;
		this.setState({showcargo: false});
		
			fetch('https://app.portda.com/api/get-cargos/'+this.props.port_id, {
			  method: 'POST',
			  headers: {
				'Content-Type'  : 'application/json',
					'PortDA-Allowed-Users': 'PortDA_Team',
					'Authorization' : 'Basic YWRtaW5AcG9ydGRhLmNvbTo1MGNjYWQxMjE3NTI4NDEyNzMwMDdlMTUwMTE3YWViMjI3OTVmMDU4MzllYTg3N2M0NDcxMTc3MTUwYWZlNzlk'
			  },
			})
			.then((response) => response.json())
			.then((json) => {
				this.setState({showcargo: true});
			  navigation.navigate('HomeScreen', {
				posts: null,
				cargos: json.cargos,
				pdas: null,
				activevessels: null,
				companies: null,
				factories: null,
			  });
			})
			.catch((error) => console.error(error));
	}
	
	/* COMPANIES FILTER */
	getallcompanies	=	(navigation)	=>	{
		global.global_port_id				=	this.props.port_id;
		fetch('https://app.portda.com/api/getfeaturedcompanies/'+this.props.country_id+'/'+this.props.port_id, {
		  method: 'POST',
		  headers: {
			'Content-Type'  : 'application/json',
				'PortDA-Allowed-Users': 'PortDA_Team',
				'Authorization' : 'Basic YWRtaW5AcG9ydGRhLmNvbTo1MGNjYWQxMjE3NTI4NDEyNzMwMDdlMTUwMTE3YWViMjI3OTVmMDU4MzllYTg3N2M0NDcxMTc3MTUwYWZlNzlk'
		  },
		})
		.then((response) => response.json())
		.then((json) => {
		  navigation.navigate('HomeScreen', {
				companies: json.companies,
				factories: null,
				pdas: null,
				posts: null,
				activevessels: null,
		  });
		})
		.catch((error) => console.error(error));
	}
	
	
	/* SERVICES FILTER */
	getallservices	=	(navigation)	=>	{
		global.global_port_id				=	this.props.port_id;
		navigation.navigate('HomeScreen', {
				companies: 1,
				factories: null,
				pdas: null,
				posts: null,
				activevessels: null,
		  });
		  
	}
	
	/* FACTORIES FILTER */
	getallfactories	=	(navigation)	=>	{		
		global.global_port_id				=	this.props.port_id;
		this.setState({showfactories: false});
		
			fetch('https://app.portda.com/api/gettradingcompanies/'+this.props.country_id, {
			  method: 'POST',
			  headers: {
				'Content-Type'  : 'application/json',
					'PortDA-Allowed-Users': 'PortDA_Team',
					'Authorization' : 'Basic YWRtaW5AcG9ydGRhLmNvbTo1MGNjYWQxMjE3NTI4NDEyNzMwMDdlMTUwMTE3YWViMjI3OTVmMDU4MzllYTg3N2M0NDcxMTc3MTUwYWZlNzlk'
			  },
			})
			.then((response) => response.json())
			.then((json) => {
				this.setState({showfactories: true});
				
			  navigation.navigate('HomeScreen', {
				factories: json.factories,
				pdas: null,
				posts: null,
				activevessels: null,
				companies: null,
			  });
			})
			.catch((error) => console.error(error));
	}
	
	/* ACTIVE VESSELS FILTER */
	getallactivevessels	=	(navigation)	=>	{
		global.global_port_id				=	this.props.port_id;
		this.setState({showvessels: false});
		fetch('https://app.portda.com/api/getactivevessels/'+this.props.port_id, {
		  method: 'POST',
		  headers: {
			'Content-Type'  : 'application/json',
				'PortDA-Allowed-Users': 'PortDA_Team',
				'Authorization' : 'Basic YWRtaW5AcG9ydGRhLmNvbTo1MGNjYWQxMjE3NTI4NDEyNzMwMDdlMTUwMTE3YWViMjI3OTVmMDU4MzllYTg3N2M0NDcxMTc3MTUwYWZlNzlk'
		  },
		})
		.then((response) => response.json())
		.then((json) => {
		this.setState({showvessels: true});	
		  navigation.navigate('HomeScreen', {
			activevessels: json.activevessels,
			pdas: null,
			posts: null,
			companies: null,
			factories: null,
		  });
		})
		.catch((error) => console.error(error));
	}	
	
	_handleScroll = (event) => {
		const newXOffset = event.nativeEvent.contentOffset.x
		this.setState({currentXOffset:newXOffset});
	}
	
	render() {
		const { navigation } = this.props;
		const {showfactories,showcargo,showvessels,showposts,isLoading}	=	this.state;
		return (
				<SafeAreaView style={styles.container}>
					<ScrollView style={styles.containers} pagingEnabled={true} horizontal={true} ref="scrollView" showsHorizontalScrollIndicator={false} onScroll={this._handleScroll}  onContentSizeChange={(w, h) => this.setState({scrollViewWidth:w/2})} scrollEnabled={true} scrollEventThrottle={1}>
						{/*<TouchableOpacity style={{ zIndex:1}}  onPress={() => this.open(navigation)}>
							<Icons name="form" size={35} color="black" style={styles.pdabutton} />
						</TouchableOpacity>*/}												
						<TouchableOpacity style={[styles.button, {width:100}]} underlayColor='#fff' onPress={()=>this.getallservices(navigation)}>
							<Text style={styles.titlestyle}>Services</Text>
						</TouchableOpacity>
						<TouchableOpacity style={[styles.button, {width:100}]} underlayColor='#fff' onPress={() => this.getallfactories(navigation)}>
							{(this.state.showfactories) ? <Text style={styles.titlestyle}>Factories</Text>	:	<ActivityIndicator animating={this.state.isLoading} size="small" color="#fff"  />}
						</TouchableOpacity>
						<TouchableOpacity style={[styles.button, {width:100}]} underlayColor='#fff' onPress={() => this.getallcargos(navigation)}>
							{(this.state.showcargo) ? <Text style={styles.titlestyle}>Cargoes</Text>	:	<ActivityIndicator animating={this.state.isLoading} size="small" color="#fff"  />}
						</TouchableOpacity>
						<TouchableOpacity style={[styles.button, {width:120}]} underlayColor='#fff' onPress={() => this.getallactivevessels(navigation)}>
							{(this.state.showvessels) ? <Text style={styles.titlestyle}>Vessels</Text>	:	<ActivityIndicator animating={this.state.isLoading} size="small" color="#fff"  />}
						</TouchableOpacity>
						<TouchableOpacity style={[styles.button, {width:100}]} underlayColor='#fff' onPress={()=>this.getallposts(navigation)}> 
							{(this.state.showposts) ? <Text style={styles.titlestyle}>Posts</Text>	:	<ActivityIndicator animating={this.state.isLoading} size="small" color="#fff"  />}
						</TouchableOpacity>
						<TouchableOpacity style={[styles.button, {width:135}]} underlayColor='#fff' onPress={() => navigation.navigate('Root',
						   {screen: 'Port Information', params: {port_id: this.props.port_id,port_name: this.props.port_name}}
						   )}>
								<Text style={styles.titlestyle}>Port Information</Text>
						</TouchableOpacity>
						{/*<TouchableOpacity style={[styles.button, {width:125}]} underlayColor='#fff' onPress={() => this.setglobalvalues(prop.marker,prop.port_name,prop.dwt.toLocaleString(),prop.pda.toLocaleString(),prop.port_id,prop.country_id,this.props.navigation)}>
							<Text style={styles.titlestyle}>Port Charges Calculator</Text>
						</TouchableOpacity>
						<TouchableOpacity style={[styles.button, {width:125}]} underlayColor='#fff' onPress={() => this.setglobalvalues(prop.marker,prop.port_name,prop.dwt.toLocaleString(),prop.pda.toLocaleString(),prop.port_id,prop.country_id,this.props.navigation)}>
							<Text style={styles.titlestyle}>Port Charges Breakdown</Text>
						</TouchableOpacity>*/}
					</ScrollView>
				</SafeAreaView>
			)
	}
	
}	

const styles = StyleSheet.create({
	container: {
		flex: 1,
		backgroundColor:'transparent',
		zIndex: 5,
		height:45,
  },
  containers: {
		flex: 1,
		width:'100%',
		flexDirection: 'row'
  },
  button:{
    backgroundColor:'#146eb4',
	borderRadius:10,
    borderWidth: 1,
    borderColor: '#fff',
	padding:10,
	margin:1,
	fontSize: 15,height: 38,justifyContent: 'center', alignItems: 'center'
  },
   pdabutton:{
	zIndex: 1,backgroundColor:'#fff',
  },
  titlestyle:{
	fontSize: 14,color: 'white'  
  },
  textstyle:{
	fontSize: 20,color: 'grey',width:'100%',
  },
  
  buttoncontainer: {
    flex: 1,
    width,
    height: '100%',
	flexDirection: 'row'
  },
  commonTextStyle: {
    color: 'white', 
    fontSize: 18,
  },
  headerLayoutStyle: {
    width:50, 
    height: 50, 
    zIndex:1
  },
  center: {
	justifyContent: 'flex-end', alignItems: 'flex-end', width: '100%',backgroundColor:'#FFFFFF',fontSize: 25,
  },
 
});
export default TopBar;