import * as React from 'react';
import { StyleSheet, Text, View, Dimensions,TouchableOpacity } from 'react-native';
import m from './Markers.js';
import { InputAutoSuggest } from 'react-native-autocomplete-search';
import { useNavigation } from '@react-navigation/native';
import PdaForm from './PdaForm.js';
import * as global from './global';

const { width, height } = Dimensions.get('window');

const flytoPort=(map,data)=>{
		global.global_port_id			=	data[0];
		global.global_major_port_id			=	data[0];
		global.global_country_id		=	data[1];
		global.global_major_port_name		=	data[2];
		global.global_port_name		=	data[2];
		
		map.flyTo({
			center: global.gmarkers['marker_'+data[0]].getLngLat(), zoom: 7, speed: 3, curve: 1, easing(t) {
			  return t;
			}
		});
		global.gmarkers['marker_'+data[0]].togglePopup();
		
	}
	
class AutoCompleteComponent extends React.Component {
	constructor(props) {
	  super(props);
		this.state = {
			map_markers: [],
			portErr: false,
			portName: null
		};
	}	
	
	componentDidMount() {
		const { map_markers } = this.state;
		m.getallmarkers().then(response => {
			const files =	[];
			response.map((marker) => {
				files.push({
					name: marker.port_name+'-'+marker.c.country_name,
					id: [marker.port_id,marker.country_id,marker.port_name,marker.lat,marker.lng]
				})
			});
			if(files.length>0){
				this.setState({map_markers: files});
			}
		
		});
	}
	
	navigateToPort(data) {
		if(data){
			const id	=	data.id;
			const port_name		=	data.name;
			this.setState({portName: port_name});
			flytoPort(global.global_map,id);
			/* NOW CREATE A TEST ENQUIRY*/		
			const { navigation } = this.props;
			new PdaForm().pdaSubmit(navigation);	
		}
	}
	
	render() {
		const { map_markers,portName } = this.state;		
		
		if(this.state.map_markers.length==0){
				return null;
		}else{
			  return (
					<View style={styles.autocompleteContainer}>
					  <InputAutoSuggest
						style={{ flex: 1,zIndex:1 }}
						inputStyle={{ zIndex:5}}
						flatListStyle={{ zIndex:3}}
						itemTextStyle={{ zIndex:4,padding:10}}
						staticData={map_markers}
						onDataSelectedChange={data => this.navigateToPort(data)}
						defaultValue={portName}
						/>
						{ this.state.portErr && 
						<Text style={styles.port_err}>*Please Select The Port To Calculate PDA</Text>
						}
					</View>
			  );
		}
	}
}

const styles = StyleSheet.create({
  autocompleteContainer: {
    flex: 1,
	backgroundColor:'#FFFFFF',width:'98%',marginLeft:45
  },
   port_err: {
    color:'red',
	fontSize:12,
	position:'absolute',
	top:'-10px',
	left:0
  }
});


export default AutoCompleteComponent;
