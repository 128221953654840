import React, { Component,useState,useEffect } from 'react';
import {
  AppRegistry,
  Platform,
  StyleSheet,
  Text,
  View,
  ScrollView,
  SafeAreaView,
  Image,
  TouchableOpacity,Dimensions,StatusBar
} from 'react-native';
import Constants from 'expo-constants';

import AutoCompleteComponent from './components/Autocomplete.js';
import { Card, ListItem, Header, Button } from 'react-native-elements';
import { createAppContainer } from 'react-navigation';
import { createMaterialBottomTabNavigator } from '@react-navigation/material-bottom-tabs';
import { NavigationContainer } from '@react-navigation/native';
import { createStackNavigator} from '@react-navigation/stack';
import { createDrawerNavigator,DrawerContentScrollView,
  DrawerItemList,
  DrawerItem, } from '@react-navigation/drawer';
import ChangePasswordScreen from './ChangePasswordScreen';
import HomeScreen from './HomeScreen';
import InformationScreen  from './InformationScreen';
import ActiveVesselScreen from './ActiveVesselScreen';
import ServiceScreen from './ServiceScreen';
import ListScreen from './ListScreen';
import SearchScreen from './SearchScreen';
import SinglePostScreen from './SinglePostScreen';
import PostsScreen from './PostsScreen';
import ProfileScreen from './ProfileScreen';
import NewPostScreen from './NewPostScreen';
import SignupScreen from './SignupScreen';
import LoginScreen from './LoginScreen';
import NotificationsScreen from './NotificationsScreen';
import CreatePostScreen from './CreatePostScreen';
import Icon from 'react-native-vector-icons/Ionicons';
import LoginIcon from 'react-native-vector-icons/FontAwesome';
import NewPost from 'react-native-vector-icons/Entypo';
import AsyncStorage from '@react-native-community/async-storage';
import { useNavigation } from '@react-navigation/native';
import PdaForm from './components/PdaForm.js';
import * as global from './components/global';
const Tab = createMaterialBottomTabNavigator();
const { width, height } = Dimensions.get('window');

const styles = StyleSheet.create({
bodyViewStyle: {
    flex: 1,
    justifyContent: 'center', 
    alignItems: 'center',
  },
  slidingPanelLayoutStyle: {
    width,
    height, 
    backgroundColor: '#fff', 
    justifyContent: 'flex-end', 
    alignItems: 'flex-end',
	zIndex:1
  },
  commonTextStyle: {
    color: 'white', 
    fontSize: 18,
  },
  headerLayoutStyle: {
    width:50, 
    height: 50, 
    justifyContent: 'center', 
    alignItems: 'center',
	zIndex:1
  },
  center: {
	justifyContent: 'center',
	alignItems: 'center',
	flex: 2,
	width: '100%',backgroundColor:'#FFFFFF',fontSize: 18,
  },
  scrollView: {
    backgroundColor: '#FFFFFF',
    marginHorizontal: 10,
  },
   buttoncontainer: {
    flex: 1,
    width,
    height: '100%',
	flexDirection: 'row',
  },
});


function HomeIcon(){
	return ( <Icon name="home" size={25} color="white" />);
}


function PostIcon(){
	return (<Icon name="bars" size={25} color="white" />);
}

function NewPostIcon(){
	return (<Icon name={Platform.OS === "ios" ? "ios-add-circle" : "md-add-circle"} size={25} color="white" />);
}

function ProfileIcon(){
	return (<Icon name={Platform.OS === "ios" ? "ios-person" : "md-person"} size={25} color="white" />);
}

function NotificationsIcon(){
	return (<Icon name={Platform.OS === "ios" ? "ios-notifications" : "md-notifications"} size={25} color="white" />);
}



const HomeStack = createStackNavigator();
const PostStack = createStackNavigator();
const NewPostStack = createStackNavigator();
const ProfileStack = createStackNavigator();
const ChangePasswordStack = createStackNavigator();
const Stack = createStackNavigator();
const Drawer = createDrawerNavigator();

StatusBar.setHidden(true);


function Root({route,navigation,props}) {
	
	global.global_major_port_id	=	route.params.params.portid;
	global.global_port_name	=	route.params.params.port_name;
	global.global_country_id	=	route.params.params.countryid;
	global.global_major_country_name	=	route.params.params.country_name;
	
	console.log(props);
	
  return (
    <Stack.Navigator initialRouteName="PDA Estimation" >
		<Stack.Screen name="PDA Estimation" component={ListScreen} options={{
			headerTintColor: '#000',
			headerTitle: ()=> (<Text style={{ fontSize:20,fontWeight:'bold',justifyContent: 'center',alignItems: 'center' }}>PDA Estimation</Text>),
			headerLeft: () => (
				  <TouchableOpacity onPress={() => navigation.goBack()} style={{marginLeft:10,backgroundColor:'#146eb4',
					borderRadius:10,
					borderWidth: 1,
					borderColor: '#fff',color:'#fff',
					padding:10,
					margin:1,
					fontSize: 15,width: 'auto',height: 38,justifyContent: 'center', alignItems: 'center'}}>
					<Text style={{color:'#fff'}}>Back</Text>
				  </TouchableOpacity>
			)
		}}/>
		<Stack.Screen name="Port Service" component={ServiceScreen} options={{
			headerTintColor: '#000',
			headerTitle: ()=> (<Text style={{ fontSize:20,fontWeight:'bold',justifyContent: 'center',alignItems: 'center' }}>Port Service</Text>),
			headerLeft: () => (
				  <TouchableOpacity onPress={() => navigation.goBack()} style={{marginLeft:10,backgroundColor:'#146eb4',
					borderRadius:10,
					borderWidth: 1,
					borderColor: '#fff',
					padding:10,
					margin:1,
					fontSize: 15,width: 'auto',height: 38,justifyContent: 'center', alignItems: 'center'}}>
					<Text style={{color:'#fff'}}>Back</Text>
				  </TouchableOpacity>
			)
		}}/>
		<Stack.Screen name="Active Vessels" component={ActiveVesselScreen} options={{
			headerTintColor: '#000',
			headerTitle: ()=> (<Text style={{ fontSize:20,fontWeight:'bold',justifyContent: 'center',alignItems: 'center' }}>Active Vessel</Text>),
			headerLeft: () => (
				  <TouchableOpacity onPress={() => navigation.goBack()} style={{marginLeft:10,backgroundColor:'#146eb4',
					borderRadius:10,
					borderWidth: 1,
					borderColor: '#fff',
					padding:10,
					margin:1,
					fontSize: 15,width: 'auto',height: 38,justifyContent: 'center', alignItems: 'center'}}>
					<Text style={{color:'#fff'}}>Back</Text>
				  </TouchableOpacity>
			)
		}}/>
		<Stack.Screen name="Port Information" component={InformationScreen} options={{
			headerTintColor: '#000',
			headerTitle: ()=> (<Text style={{ fontSize:20,fontWeight:'bold',justifyContent: 'center',alignItems: 'center' }}>Port Information</Text>),
			headerLeft: () => (
				  <TouchableOpacity onPress={() => navigation.goBack()} style={{marginLeft:10,backgroundColor:'#146eb4',
					borderRadius:10,
					borderWidth: 1,
					borderColor: '#fff',
					padding:10,
					margin:1,
					fontSize: 15,width: 'auto',height: 38,justifyContent: 'center', alignItems: 'center'}}>
					<Text style={{color:'#fff'}}>Back</Text>
				  </TouchableOpacity>
			)
		}}/>
		<Stack.Screen name="SinglePost" component={SinglePostScreen} options={{
			headerTintColor: '#000',
			headerTitle: ()=> (<Text style={{ fontSize:20,fontWeight:'bold',justifyContent: 'center',alignItems: 'center' }}>Posts</Text>),
			headerLeft: () => (
				  <TouchableOpacity onPress={() => navigation.goBack()} style={{marginLeft:10,backgroundColor:'#146eb4',
					borderRadius:10,
					borderWidth: 1,
					borderColor: '#fff',
					padding:10,
					margin:1,
					fontSize: 15,width: 'auto',height: 38,justifyContent: 'center', alignItems: 'center'}}>
					<Text style={{color:'#fff'}}>Back</Text>
				  </TouchableOpacity>
			),
			headerRight: () => (<TouchableOpacity onPress={() =>  navigation.navigate('PostsScreen', {
            portid: global.global_major_port_id,countryid: global.global_country_id,port_name: global.global_port_name,country_name: global.global_major_country_name
		})} style={{marginLeft:10,backgroundColor:'#146eb4',
					borderRadius:10,
					borderWidth: 1,
					borderColor: '#fff',
					padding:10,
					margin:1,
					fontSize: 15,width: 'auto',height: 38,justifyContent: 'center', alignItems: 'center'}}>
					<Text style={{color:'#fff'}}>All Posts</Text>
			</TouchableOpacity>)
		}}/>
    </Stack.Navigator>
  );
}


const HomeStackScreen = ({ route, navigation }) => {
	
	if(route.params){
		var user_id	= route.params.user_id;
		if(user_id>0){
			var email = "yes";
		}else{
			var email = "no";
		}
	}else{
		var email = "no";
	}

 return ( <HomeStack.Navigator screenOptions={{
    headerStyle:{
		backgroundColor:'#fff',
		height:Platform.OS=='web'?50:100
    },
    headerTintColor:"#fff",
    headerTitleStyle:{
      fontWeight:"bold",position: 'absolute'
    }
  }}>
    <HomeStack.Screen name="HomeScreen" component={HomeScreen} initialParams={{ pdas: "default"}} options={{
		headerLeft: () => (<TouchableOpacity style={{ flex: 1,flexDirection: 'row',zIndex:1}} >
							<Image source={require('./assets/PortDALogoNew2020.png')} style={{ width: 100,height: 35,top:0,zIndex:0,margin:10}} />
						  </TouchableOpacity>),
		headerTitle: ()=> (<AutoCompleteComponent style={{ width: '50%',zIndex:0,position:'absolute',flex: 4 }}	navigation={navigation}   />),
        headerRight: () => {
			if(email=="yes"){
				return (<TouchableOpacity style={{ flex: 1,flexDirection: 'row',zIndex:1,padding:5,position:'absolute',borderLeftWidth: 1,borderColor: '#146eb4',}} onPress={() => navigation.navigate('ProfileScreen')} >
							<LoginIcon name="user" size={31} color="#000"></LoginIcon>
						</TouchableOpacity>);
			}else{
				return (<TouchableOpacity style={{ flex: 1,flexDirection: 'row',zIndex:1,padding:5,position:'absolute',borderLeftWidth: 1,borderColor: '#146eb4',}} onPress={() => navigation.navigate('Login')} >
							<LoginIcon name="user" size={31} color="#000"></LoginIcon>
						</TouchableOpacity>);
			}
		}
    }} />
	
  </HomeStack.Navigator>);
};

const ChangePasswordStackScreen = ({navigation}) => (
  <ChangePasswordStack.Navigator screenOptions={{
    headerStyle:{
		backgroundColor:'#fff',
		height:Platform.OS=='web'?50:100
    },
    headerTintColor:"#fff",
    headerTitleStyle:{
      fontWeight:"bold",position: 'absolute'
    }
  }}>
    <ChangePasswordStack.Screen name="ChangePassword" component={ChangePasswordScreen} options={{
			headerTintColor: '#000',
			headerTitle: ()=> (<Text style={{ fontSize:20,fontWeight:'bold',justifyContent: 'center',alignItems: 'center' }}>Change Password</Text>),
			headerLeft: () => (
				  <TouchableOpacity onPress={() => navigation.goBack()} style={{marginLeft:10,backgroundColor:'#146eb4',
					borderRadius:10,
					borderWidth: 1,
					borderColor: '#fff',color:'#fff',
					padding:10,
					margin:1,
					fontSize: 15,width: 'auto',height: 38,justifyContent: 'center', alignItems: 'center'}}>
					<Text style={{color:'#fff'}}>Back</Text>
				  </TouchableOpacity>
			)
		}}/>
	
  </ChangePasswordStack.Navigator>
);

const ProfileStackScreen = ({navigation}) => (
  <ProfileStack.Navigator screenOptions={{
    headerStyle:{
		backgroundColor:'#fff',
		height:Platform.OS=='web'?50:100
    },
    headerTintColor:"#fff",
    headerTitleStyle:{
      fontWeight:"bold",position: 'absolute'
    }
  }}>
    <ProfileStack.Screen name="ProfileScreen" component={ProfileScreen} options={{
			headerTintColor: '#000',
			headerTitle: ()=> (<Text style={{ fontSize:20,fontWeight:'bold',justifyContent: 'center',alignItems: 'center' }}>My Profile</Text>),
			headerLeft: () => (
				  <TouchableOpacity onPress={() => navigation.goBack()} style={{marginLeft:10,backgroundColor:'#146eb4',
					borderRadius:10,
					borderWidth: 1,
					borderColor: '#fff',color:'#fff',
					padding:10,
					margin:1,
					fontSize: 15,width: 'auto',height: 38,justifyContent: 'center', alignItems: 'center'}}>
					<Text style={{color:'#fff'}}>Back</Text>
				  </TouchableOpacity>
			)
		}}/>
	
  </ProfileStack.Navigator>
);



const PostStackScreen = ({route,navigation}) => {
		
		var value_2 =  global.user_id;
		if (value_2 == 0 || value_2 === undefined) {
			var email_2	=	"no";
		}else{
			var email_2	=	"yes";
		}
		
		
		return  (<PostStack.Navigator screenOptions={{
			headerStyle:{
				backgroundColor:'#fff',
				height:Platform.OS=='web'?50:100
			},
			headerTintColor:"#fff",
			headerTitleStyle:{
			  fontWeight:"bold",position: 'absolute'
			}
		  }}>
			<PostStack.Screen name="PostsScreen" component={PostsScreen} initialParams={{ port_id: global.global_major_port_id}} options={{
					headerTintColor: '#000',
					headerTitle: ()=> (<Text style={{ fontSize:20,fontWeight:'bold',justifyContent: 'center',alignItems: 'center' }}>All Posts</Text>),
					headerLeft: () => (
						  <TouchableOpacity onPress={() => navigation.goBack()} style={{marginLeft:10,backgroundColor:'#146eb4',
							borderRadius:10,
							borderWidth: 1,
							borderColor: '#fff',color:'#fff',
							padding:10,
							margin:1,
							fontSize: 15,width: 'auto',height: 38,justifyContent: 'center', alignItems: 'center'}}>
							<Text style={{color:'#fff'}}>Back</Text>
						  </TouchableOpacity>
					),
					headerRight: () => {
						if(email_2=="yes"){
						return (<TouchableOpacity onPress={() =>  navigation.navigate('NewPost',{user_id:value_2,portid:route.params.portid,port_name:route.params.port_name,countryid:route.params.countryid,country_name:route.params.country_name })}
						style={{marginLeft:10,backgroundColor:'#146eb4',
								borderRadius:10,
								borderWidth: 1,
								borderColor: '#fff',
								padding:10,
								margin:1,
								fontSize: 15,width: 'auto',height: 38,justifyContent: 'center', alignItems: 'center'}}>
								<Text style={{color:'#fff'}}>Add Post</Text>
							</TouchableOpacity>);
						}else{
						return (<TouchableOpacity onPress={() =>  navigation.navigate('Login')} style={{marginLeft:10,backgroundColor:'#146eb4',
									borderRadius:10,
									borderWidth: 1,
									borderColor: '#fff',
									padding:10,
									margin:1,
									fontSize: 15,width: 'auto',height: 38,justifyContent: 'center', alignItems: 'center'}}>
									<Text style={{color:'#fff'}}>Add Post</Text>
								</TouchableOpacity>);
					}
			}}} />			
		  </PostStack.Navigator>
		);
}

const NewPostStackScreen = ({route,navigation}) => {	
	
	global.global_major_port_id	=	route.params.portid;
	global.global_port_name	=	route.params.port_name;
	global.global_country_id	=	route.params.countryid;
	global.global_major_country_name	=	route.params.country_name;
	return (<NewPostStack.Navigator screenOptions={{
    headerStyle:{
		backgroundColor:'#fff',
		height:Platform.OS=='web'?50:100
    },
    headerTintColor:"#fff",
    headerTitleStyle:{
      fontWeight:"bold",position: 'absolute'
    }
  }}>
    <NewPostStack.Screen name="NewPost" component={ props => <NewPostScreen port_id={route.params.portid} />} options={{
			headerTintColor: '#000',
			headerTitle: ()=> (<Text style={{ fontSize:20,fontWeight:'bold',justifyContent: 'center',alignItems: 'center' }}>Create Post</Text>),
			headerLeft: () => (
				  <TouchableOpacity onPress={() => navigation.goBack()} style={{marginLeft:10,backgroundColor:'#146eb4',
					borderRadius:10,
					borderWidth: 1,
					borderColor: '#fff',color:'#fff',
					padding:10,
					margin:1,
					fontSize: 15,width: 'auto',height: 38,justifyContent: 'center', alignItems: 'center'}}>
					<Text style={{color:'#fff'}}>Back</Text>
				  </TouchableOpacity>
			)
		}}/>
	
  </NewPostStack.Navigator>
);
}

function App() {
	return (
	<NavigationContainer>
		<Drawer.Navigator drawerContent={props => <PdaForm {...props} /> } drawerStyle={{width : 325}} >
			<Drawer.Screen name="Home"  component={HomeStackScreen} />
			<Drawer.Screen name="Login" component={LoginScreen}	/>
			<Drawer.Screen name="PostsScreen" component={PostStackScreen}	/>
			<Drawer.Screen name="NewPost" component={NewPostStackScreen}	/>
			<Drawer.Screen name="Signup" component={SignupScreen}	/>
			<Drawer.Screen name="ProfileScreen" component={ProfileStackScreen}	/>
			<Drawer.Screen name="ChangePassword" component={ChangePasswordStackScreen}	/>
			<Drawer.Screen name="Root"  component={Root} />
		</Drawer.Navigator>
	</NavigationContainer>
	);
}


if(Platform.OS=='web'){
	
	AppRegistry.registerComponent('App', () => App);
	AppRegistry.runApplication('App', { rootTag: document.getElementById('root') });
}

export default App;