import React, { Component, useState } from 'react';
import {
  Text,
  View,
  Image,
  StyleSheet,
  TouchableOpacity,
  TextInput,
  Input,
  Button,
  Keyboard,
  Platform,
  CheckBox,Dimensions,ActivityIndicator
} from 'react-native';
import RadioGroup,{Radio} from "react-native-radio-input";
import Icon from 'react-native-vector-icons/Feather';
import HeaderComponent from './components/HeaderComponent';
import AsyncStorage from '@react-native-community/async-storage';
import * as ImagePicker from 'expo-image-picker';
import { Buffer } from 'buffer';
import * as global from './components/global';
const { width, height } = Dimensions.get('window');

class NewPostScreen extends Component {
   constructor(props){
      super(props)
      this.state = {
        postTitle:"",
        postDescription:"",
        hashTags:"",
        postImage:"",
        radioButton:'1',
		showbutton:true,
		title_err:false,
		content_err:false
      };
    }

    getChecked = (value) => {
      // value = our checked value
      console.log(value);
      this.setState({radioButton:value});
    }
	
	
  _pickImage = async() => {
    try {
      
      let result = await ImagePicker.launchImageLibraryAsync({
        mediaTypes: ImagePicker.MediaTypeOptions.All,
        allowsEditing: true,
        aspect: [4, 3],
        quality: 1,
      });
      if (!result.cancelled) {
        this.setState({ postImage: result.uri });
      }
      let imageuri = result.uri;
      
      console.log(this.state.postImage);
    } catch (E) {
      console.log(E);
    }
  };
  submitPost = () => {
	this.setState({showbutton:false});
    const {postTitle} = this.state;
    const {postDescription} = this.state;
    const {hashTags} = this.state;
    const {user_id} = this.state;
    const {postImage} = this.state;
    const {port_id}  = global.global_major_port_id;
    const {radioButton} = this.state;
	
	if(postTitle==''){
		this.setState({title_err:true,showbutton:true,content_err:false});
		return;
	}
	if(postDescription==''){
		this.setState({content_err:true,showbutton:true,title_err:false});
		return;
	}
	
	if(postTitle!='' && postDescription!=''){
		this.setState({title_err:false,content_err:false,showbutton:false});
	}

     AsyncStorage.multiGet(['user']).then((Userdata) => {
          let user = Userdata[0][1];
          user = JSON.parse(user);
          console.log(user);
          let userId = user.id;
          
          fetch('https://app.portda.com/api/createpost',{
                  method:'post',
                  headers: {
                              'Content-Type'  : 'application/json',
                              'PortDA-Allowed-Users': 'PortDA_Team',
                              'Authorization' : 'Basic YWRtaW5AcG9ydGRhLmNvbTo1MGNjYWQxMjE3NTI4NDEyNzMwMDdlMTUwMTE3YWViMjI3OTVmMDU4MzllYTg3N2M0NDcxMTc3MTUwYWZlNzlk'
                            },
                  body:JSON.stringify({
                    title:postTitle,
                    post:postDescription,
                    user_id:userId,
                    postImage:this.state.postImage,
                    ports:this.state.radioButton,
                    country:global.global_country_id
                  })
               }).then((response)=>response.json())
                  .then((responseJson)=>{
                    this.setState({showbutton:true});
                    global.navigation.navigate('PostsScreen',{option:'update'});
                  })
                  .catch((error)=>{
					  this.setState({showbutton:true});
                    console.error(error);
                  });
          
     });

     Keyboard.dismiss();
  }
  render () { 
    const {postImage} = this.state;
	console.log(this.props);
	return (
      <View style={styles.container} >
        <View style={styles.profileInfo}>
          <View>
            <Image
				style={styles.tinyLogo}
				source={require('./assets/PortDALogoNew2020.png')}
			/>
          </View>
          <View style={{flexDirection: 'row'}}>
          <View style={styles.buttonStyle}>
            <Icon name="user" size={20} />
            <Text style={styles.buttonText}>PortDA</Text>
          </View>
          </View>
        </View>
        <View style={{ flex: 1,padding:5 }}>
          <TextInput 
            multiline
            numberOfLines={3}
            placeholder="Post Title" 
            onChangeText = {postTitle =>this.setState({postTitle})}
			style={{borderBottomWidth:1,borderColor:'lightgrey',height:50}}
          />
		  {(this.state.title_err)?<View style={{ flex: 1,zIndex: 1}}><Text style={styles.error}>*Post Title Invalid / empty</Text></View>:null}
			
          <TextInput
            multiline
            numberOfLines={15}
			placeholder="What do you want to talk about?"
            onChangeText = {postDescription =>this.setState({postDescription})}
			style={{borderBottomWidth:1,borderColor:'lightgrey',height:height-450}}
          />
		  {(this.state.content_err)?<View style={{ flex: 1,zIndex: 1}}><Text style={styles.error}>*Post Content Invalid / empty</Text></View>:null}
          <TextInput numberOfLines={1} style={{fontSize: 15, alignSelf: 'stretch',height:50}} placeholder="Add tags & make the right people see your post"
            onChangeText = {hashTags =>this.setState({hashTags})}
          />
          <Button title="Upload File" style={{color:'black',backgroundColor:'white'}} onPress={this._pickImage} />
          <View style={{flex:1}}><Image source={{uri:this.state.postImage}} style={{flex: 1,aspectRatio: 1.5,resizeMode: 'contain'}} /></View>	  
          <Text style={{marginRight:5,fontSize:15}}>Post In</Text>
          <RadioGroup getChecked={this.getChecked} RadioGroupStyle={{flexDirection: (width>748) ? "row" : "column" }}>
              <Radio iconName={"lens"} label={global.global_port_name} value={1}/>
              <Radio iconName={"lens"} label={"All ports of "+global.global_major_country_name+""} value={2}/>
			  {/*<Radio iconName={"lens"} label={"All ports of the world"} value={3}/>*/}
          </RadioGroup>
        </View>
        <View style={{backgroundColor:'#146eb4'}}>
          {(this.state.showbutton) ? <Button title="Submit Post" color='#146eb4' width={30} onPress={this.submitPost}/> 	:	<ActivityIndicator size="large" color="#fff"  />}
        </View>
      </View>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    width: '100%',
    height: '100%',
    backgroundColor: 'white',
  },
  back: {
	 backgroundColor: '#f90', 
  },
  tinyLogo: {
    width: 75,
    height: 75,
    borderRadius: 50,
    margin: 5,
    flex: 1,resizeMode: 'contain',
  },
  profileInfo: {
    flexDirection: 'row',
    alignItems: 'flex-start',
    justifyContent: 'flex-start'
  },
  buttonText: {
    fontSize: 12,
    textAlign: 'center',
    textAlignVertical: 'center',
    alignSelf: 'center',
    paddingLeft: 5,
    paddingRight: 5,
  },
  buttonStyle: {
    flexDirection: 'row',
    borderStyle: 'solid',
    borderColor: 'grey',
    borderWidth: 1,
    marginTop: 15,
    marginBottom: 15,
    margin: 5,
    padding: 2,
    borderRadius: 5,
    justifyContent: 'center',
    alignItems: 'center',
    height: 50
  },
  header: {
    backgroundColor: '#f90',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: 5,
    paddingTop: 20,
  },
  headerText: {
    fontSize: 15,
    textAlign: 'center',
    flex: 1,
    color: '#fff',
  },
  bottom: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignContent: 'stretch',
    margin: 10
  },
  bottomIcons: {
flex: 1,
 alignItems: 'center'
  },
  error: {
    color:'red',
	fontSize:12,
  },
});

export default NewPostScreen;