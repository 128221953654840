import React, { Component, useState } from 'react';
import {
  Text,
  View,
  Image,
  StyleSheet,
  TouchableOpacity,
  TextInput,
  Input,
  Button,
  Keyboard
} from 'react-native';
import AsyncStorage from '@react-native-community/async-storage';
import Icon from 'react-native-vector-icons/Feather';
import * as ImagePicker from 'expo-image-picker';
import { Buffer } from 'buffer';

class NewPostScreen extends Component {
   constructor(props){
      super(props)
      this.state = {
        postTitle:"",
        postDescription:"",
        hashTags:"",
        postImage:""
      }
    }

  _pickImage = async() => {
    // console.log(event.target);
    // this.setState({postImage:event.target.files[0]});
    // console.log(this.state.postImage);

    try {
      let result = await ImagePicker.launchImageLibraryAsync({
        mediaTypes: ImagePicker.MediaTypeOptions.All,
        allowsEditing: true,
        aspect: [4, 3],
        quality: 1,
      });
      if (!result.cancelled) {
        this.setState({ postImage: result.uri });
        // console.log(Buffer.from(this.state.postImage).toString('base64'));
        // console.log(result);
      }
      let imageuri = result.uri;
      console.log(imageuri);
    } catch (E) {
      console.log(E);
    }
  };
  submitPost = () => {

    const {postTitle} = this.state;
    const {postDescription} = this.state;
    const {hashTags} = this.state;
    const {user_id} = this.state;
    const {postImage} = this.state;

     AsyncStorage.multiGet(['user']).then((Userdata) => {
          let user = Userdata[0][1];
          user = JSON.parse(user);
          console.log(user);
          let userId = user.id;
          console.log('postImage::=>',this.state.postImage)

          // const data = new FormData();
          // data.append('post_id',userId);
          // data.append('postImage', {
          //     uri: this.state.postImage,
          //     name:'somename.png'
          // });
          
          fetch('https://www.dev.portda.com/api/createpost',{
                  mode:'no-cors',
                  method:'post',
                  headers: {
                              'Content-Type'  : 'application/json',
                              'PortDA-Allowed-Users': 'PortDA_Team',
                              'Authorization' : 'Basic YWRtaW5AcG9ydGRhLmNvbTo1MGNjYWQxMjE3NTI4NDEyNzMwMDdlMTUwMTE3YWViMjI3OTVmMDU4MzllYTg3N2M0NDcxMTc3MTUwYWZlNzlk'
                            },
                  body:JSON.stringify({
                    title:postTitle,
                    post:postDescription,
                    hashtags:hashTags,
                    user_id:userId,
                    postImage:this.state.postImage
                  })
               }).then((response)=>response.json())
                  .then((responseJson)=>{
                    console.log(responseJson);
                    alert(JSON.stringify(responseJson));
                    
                    this.props.navigation.navigate('Posts');

                  })
                  .catch((error)=>{
                    console.error(error);
                  });
          
     });

     Keyboard.dismiss();

               

  }
  render () { 
    const {postImage} = this.state;
    return (
      <View style={styles.container}>
        <View style={styles.profileInfo}>
          <View>
            <Image
              style={styles.tinyLogo}
              source={require('./assets/PortDAThumbnailNew.png')}
            />
          </View>
          <View style={{flexDirection: 'row'}}>
          <TouchableOpacity style={styles.buttonStyle}>
            <Icon name="user" size={20} />
            <Text style={styles.buttonText}>PortDA Team</Text>
            <Icon name="chevron-down" size={20} />
          </TouchableOpacity>
          <TouchableOpacity style={styles.buttonStyle}>
            <Icon name="globe" size={20} />
            <Text style={styles.buttonText}>Anyone</Text>
            <Icon name="chevron-down" size={20} />
          </TouchableOpacity>
          </View>
        </View>
        <View style={{ flex: 2 }}>
          <TextInput 
            multiline
            numberOfLines={3}
            placeholder="Post Title" 
            onChangeText = {postTitle =>this.setState({postTitle})}
          />
        </View>
        <View style={{ flex: 7 }}>
          <TextInput
            multiline
            numberOfLines={15}
            placeholder="What do you want to talk about?"
            onChangeText = {postDescription =>this.setState({postDescription})}
          />
        </View>
        <View style={{ flexDirection: 'row' }}>
          <Text style={{flex: 1, fontSize: 10, padding: 10, alignSelf: 'center'}}># Add hashtag</Text>
          <TextInput style={{flex: 2, fontSize: 10, alignSelf: 'stretch'}} placeholder="Help the right people see your post"
            onChangeText = {hashTags =>this.setState({hashTags})}
          />
        </View>
         <View style={{ flexDirection: 'row' , flex:15}}>
          <Button title="Upload File" style={{color:'black',backgroundColor:'white'}} onPress={this._pickImage} />
          <Image source={{uri:this.state.postImage}} style={{flex: 1,aspectRatio: 1.5,resizeMode: 'contain'}} />
        </View>
        <View style={{backgroundColor:'#146eb4'}}>
          <Button title="Submit Post" color='black' width={30} onPress={this.submitPost}/> 
        </View>
        <View style={styles.bottom}>
        <TouchableOpacity style={styles.bottomIcons}><Icon name="camera" size={15} /></TouchableOpacity>
        <TouchableOpacity style={styles.bottomIcons}><Icon name="video" size={15} /></TouchableOpacity>
        <TouchableOpacity style={styles.bottomIcons}><Icon name="image" size={15} /></TouchableOpacity>
        <TouchableOpacity style={styles.bottomIcons}><Icon name="more-horizontal" size={15} /></TouchableOpacity>
        <TouchableOpacity style={styles.bottomIcons}><Icon name="at-sign" size={15} /></TouchableOpacity>
        </View>
      </View>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    width: '100%',
    height: '100%',
    backgroundColor: 'white',
  },
  back: {
	 backgroundColor: '#f90', 
  },
  tinyLogo: {
    width: 50,
    height: 50,
    borderRadius: 200 / 2,
    margin: 5,
    flex: 1,
  },
  profileInfo: {
    flexDirection: 'row',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    marginBottom: 5,
  },
  buttonText: {
    fontSize: 12,
    textAlign: 'center',
    textAlignVertical: 'center',
    alignSelf: 'center',
    paddingLeft: 5,
    paddingRight: 5,
  },
  buttonStyle: {
    flexDirection: 'row',
    borderStyle: 'solid',
    borderColor: 'grey',
    borderWidth: 1,
    marginTop: 15,
    marginBottom: 15,
    margin: 5,
    padding: 2,
    borderRadius: 5,
    justifyContent: 'center',
    alignItems: 'center',
    height: 50
  },
  header: {
    backgroundColor: '#f90',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: 5,
    paddingTop: 20,
  },
  headerText: {
    fontSize: 15,
    textAlign: 'center',
    flex: 1,
    color: '#fff',
  },
  bottom: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignContent: 'stretch',
    margin: 10
  },
  bottomIcons: {
flex: 1,
 alignItems: 'center'
  }
});

export default NewPostScreen;