import React, {Component} from 'react';
import { StyleSheet, Button, View, SafeAreaView, Text, Alert,Keyboard } from 'react-native';
import { Input } from 'react-native-elements';
import { createAppContainer } from 'react-navigation-stack';
import Footer from "./Footer";
import TabNavigator from "./TabNav";
import ProfileScreen from "./ProfileScreen";
import AsyncStorage from '@react-native-community/async-storage';

export default class ChangePasswordScreen extends Component  {

	constructor(props){
      super(props);
      this.state = {
        old_pwd:"",
        new_pwd:"",
        confirm_pwd:""
      }
    }

    _submitPwd = () => {
    	const{old_pwd} = this.state;
	    const{new_pwd} = this.state;
	    const{confirm_pwd} = this.state;

	    Keyboard.dismiss();
	    if(new_pwd == confirm_pwd){
	    	AsyncStorage.multiGet(['user']).then((Userdata) => {
		          let user = Userdata[0][1];
		          user = JSON.parse(user);
		          console.log(user);
		          let userId = user.id;
		          // alert(userId);

		         fetch('https://app.portda.com/api/changepassword',{
			          method:'post',
	                  headers: {
	                              'Content-Type'  : 'application/json',
	                              'PortDA-Allowed-Users': 'PortDA_Team',
	                              'Authorization' : 'Basic YWRtaW5AcG9ydGRhLmNvbTo1MGNjYWQxMjE3NTI4NDEyNzMwMDdlMTUwMTE3YWViMjI3OTVmMDU4MzllYTg3N2M0NDcxMTc3MTUwYWZlNzlk'
	                            },
	                  body:JSON.stringify({
	                  	old_pwd:old_pwd,
	                  	new_pwd:new_pwd,
	                  	confirm_pwd:confirm_pwd,
	                  	user_id:userId
	                  })
		         }).then((response)=>response.json())
		         	.then((responseJson)=>{
	                    console.log("result=>",JSON.stringify(responseJson));
	                    alert(JSON.stringify(responseJson.status));
	                    this.setState({ old_pwd: "" })
	                    this.setState({new_pwd:""})
	                    this.setState({confirm_pwd:""})
	                    this.props.navigation.navigate('Login');
	                  })

		      });
	    }else{
	    	alert('Passwords didnot Match! Please check Again!!')
	    }
	    
    }

	render () {
		return (
			<View style={styles.container} >
				<View style={styles.subcontainer} >
					<Input placeholder="Enter Old Password" onChangeText = {old_pwd =>this.setState({old_pwd})} secureTextEntry={true}/>
					<Input placeholder="Enter New password" onChangeText = {new_pwd =>this.setState({new_pwd})} secureTextEntry={true}/>
					<Input placeholder="Confirm new pssword" onChangeText= {confirm_pwd =>this.setState({confirm_pwd})} secureTextEntry={true}/>
					<Button title="Submit" color="#146eb4" onPress={this._submitPwd}/>
				</View>
			</View>
		);
	}
}

const styles = StyleSheet.create({
  container: {
      flex:1,
	  justifyContent: 'center',
      alignItems: 'center',
	  backgroundColor:'#fff'
  },
  subcontainer: {
      flex:1,
	  width:400,
      justifyContent: 'center',
      alignItems: 'center',
	  backgroundColor:'#fff'
  }
});