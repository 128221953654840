import React, { Component } from 'react';
import {
	Platform,
	Text,
	View,
	StyleSheet,
	ScrollView,SafeAreaView,
	Image,
	TouchableOpacity,
	FlatList,Dimensions,ActivityIndicator
} from 'react-native';
import Icon from 'react-native-vector-icons/Ionicons';
import TopBar from './components/TopBar';
const { width, height } = Dimensions.get('window');
var show=false;
var initial_param = null
class InformationScreen extends Component {
	constructor(props) {
		super(props);
		this.state = {
			flags_restricted:[],
			max_loa:null,
			max_draft:null,
			night_navigation:false
		}
	}
   
   
   getinfo= () => {
		
		const {flags_restricted,max_loa,max_draft,night_navigation,isLoading}	=	this.state;
			
		fetch('https://app.portda.com/api/getportinformation',{
			  method:'post',
			  headers: {
							  'Content-Type'  : 'application/json',
							  'PortDA-Allowed-Users': 'PortDA_Team',
							  'Authorization' : 'Basic YWRtaW5AcG9ydGRhLmNvbTo1MGNjYWQxMjE3NTI4NDEyNzMwMDdlMTUwMTE3YWViMjI3OTVmMDU4MzllYTg3N2M0NDcxMTc3MTUwYWZlNzlk'
						},
			  body:JSON.stringify({
				port:this.props.route.params.port_id
			  })
		  })
		  .then((response)=>response.json())
			.then((responseJson)=>{
				if(responseJson.portinformation.restricted_flags!=null){
					if(responseJson.portinformation.restricted_flags.length>0){
						this.setState({flags_restricted: responseJson.portinformation.restricted_flags});
					}else{
						this.setState({flags_restricted: 'Not Specified'});
					}
				}else{
					this.setState({flags_restricted: 'Not Specified'});
				}
				show=true;
				this.setState({max_loa: responseJson.portinformation.loa,max_draft: responseJson.portinformation.draft,night_navigation: responseJson.portinformation.night_navigation});
				
			})
			.catch((error)=>{
			  console.error(error);
			});
			
    }
	
  render() {
    
	const {flags_restricted,max_loa,max_draft,night_navigation,isLoading}	=	this.state;
	
	if(this.props.route.params.port_id!=initial_param){
		show=false;
		initial_param=this.props.route.params.port_id;
		this.getinfo();
	}
	
	
	
	const restricted = () => {
		if(flags_restricted!='Not Specified' && flags_restricted.length>0){
			return flags_restricted.map((e, i) => 							
				<View style={styles.content} key={i}><Text>{e}</Text></View>
			);
		}else{
			return <Text style={{fontSize:15}}>Not Specified</Text>;
		}
	
	}
    return (<SafeAreaView style={styles.container}>
					<ScrollView horizontal={false} scrollEnabled={true}>
						<View style={{flex: 1,width,}}><Text style={styles.heading}>{this.props.route.params.port_name}</Text></View>
							{(show) ? 								
								<View style={{flex: 1,width,height:height-130}}>
									<View style={[styles.subcontainer,{flex: 4}]}>
										<View style={styles.leftrightcontent}>
											<View style={styles.content}><Text style={{color:'#146eb4',fontSize:20,}}>Flags Restricted</Text></View>
										</View>
										<View style={styles.leftrightcontent}>
											{restricted()}
										</View>
									</View>
									<View style={styles.subcontainer}>
										<View style={styles.leftrightcontent}>
											<View style={styles.content}><Text style={{color:'#146eb4',fontSize:20,}}>Maximum LOA</Text></View>
										</View>
										<View style={styles.leftrightcontent}>
											<View style={styles.content}><Text style={{fontSize:15}}>{this.state.max_loa}</Text></View>
										</View>
									</View>
									<View style={styles.subcontainer}>
										<View style={styles.leftrightcontent}>
											<View style={styles.content}><Text style={{color:'#146eb4',fontSize:20}}>Maximum Draft</Text></View>
										</View>
										<View style={styles.leftrightcontent}>
											<View style={styles.content}><Text style={{fontSize:15}}>{this.state.max_draft}</Text></View>
										</View>
									</View>
									<View style={styles.subcontainer}>
										<View style={styles.leftrightcontent}>
											<View style={styles.content}><Text style={{color:'#146eb4',fontSize:20}}>Night Navigation</Text></View>
										</View>
										<View style={styles.leftrightcontent}>
											<View style={styles.content}><Text style={{fontSize:15}}>{this.state.night_navigation}</Text></View>
										</View>
									</View>
								</View>
							 :
							<View style={{flex: 1,width,height:height-130}}><ActivityIndicator size="large" style={{flex: 1}} color="#146eb4"  /></View> 
							}
					</ScrollView>
			</SafeAreaView>);
  }
}

const styles = StyleSheet.create({
   container: {
    flex: 1,
    width,
	backgroundColor: '#fff', position:'absolute',top:0
  },
  subcontainer: {
    flex: 1,flexDirection:'row'
  },
  leftrightcontent:{width: '50%',padding:10,textAlign: 'left',alignItems: 'left'},
  content:{width: '100%',textAlign: 'left',padding:3},
  heading:{
	  fontSize: 25,padding:10,backgroundColor: '#f90', color:'#fff',textAlign: 'center', justifyContent: 'center',alignItems: 'center',width:'100%',height:53
  },
  expenses:{
	  fontSize: 15,padding:5,backgroundColor: '#146eb4',color:'#fff',textAlign: 'center', justifyContent: 'center',alignItems: 'center',width:'100%',
  },
  posts: {
    width: '100%',height: 'auto',flexDirection: 'row',padding:5
  },
  profileInfo: {
    flex: 2,
    flexDirection: 'row',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
  },
  tinyLogo: {
    width: 75,
    height: 75,
	resizeMode: 'contain',
  },
  icon: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row',
  },
  headerText: {
    fontSize: 20,
    textAlign: 'center',
    flex: 1,
    color: '#fff',
  },
  text: {
    fontSize: 12,textAlign: 'left',
  },
  texttitle: {
	fontSize: 15,textAlign: 'center', textDecorationLine: 'underline',fontWeight: 'bold',justifyContent: 'center',alignItems: 'center',width:'100%',marginBottom:5
  },
  postDescription: {
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
	textAlign: 'left',
    padding: 5
  },
  leftview: {
	justifyContent: 'center',
    alignItems: 'center',
    width: '25%',
	height: '100%', backgroundColor: '#fff', margin: 4,borderWidth: 1,borderColor: "lightgrey",borderRadius: 5,
  },
  rightview: {
	justifyContent: 'center',
    alignItems: 'center',
    width: '25%',height: '100%', backgroundColor: '#fff', margin: 4, borderWidth: 1,borderColor: "lightgrey",borderRadius: 5,
  },
  alttext: {
	fontSize: 12,
	color: 'lightgrey'
  }
});

export default InformationScreen;
