import React, {Component} from 'react';
import { Platform,StyleSheet, Button, View, SafeAreaView, Text, Alert,Keyboard,Dimensions,ScrollView,Picker,TouchableOpacity,ActivityIndicator} from 'react-native';
import AsyncStorage from '@react-native-community/async-storage';
import { Input } from 'react-native-elements';
import Icons from 'react-native-vector-icons/AntDesign';
import { useNavigation } from '@react-navigation/native';
import * as global from './components/global';
import Results from './components/Results';
import Map from './components/Map';
import PortSlider from './components/PortSlider';
import Icon from 'react-native-vector-icons/Ionicons';
import NewPostScreen from './NewPostScreen';

const { width, height } = Dimensions.get('window');
const date = (new Date().getDate()).toString().padStart(2, "0");
const month = (new Date().getMonth()+1).toString().padStart(2, "0"); 
const year = new Date().getFullYear();
let is_it_inside_comparedpdaSubmit	=	false;

class HomeScreen extends Component {	
		constructor(props){
		  super(props)
		  this.state = {
			status:false,
			uniqueValue: 1
		  };
		  global.navigation	=	this.props.navigation;
		}
		
		forceRemount(){
			this.setState(({ uniqueValue }) => ({
			  uniqueValue: uniqueValue + 1
			}));
		}
		

	  render() {		
		const {status} = this.state;
		const { navigation } = this.props;
		var filtertype	=	null;
		var data	=	null;
		var r = null;
		var dwt	= null;
		var markerID	= null;
		var pdaamount	= null;
		
		
		
		if(this.props.route.params){
			if(this.props.route.params.posts){
				 filtertype	=	'posts';
				 data	=	this.props.route.params.posts;
			}else if(this.props.route.params.pdas){		
				filtertype	=	'default';
				data	=	this.props.route.params.pdas;
				if(this.props.route.params.pdas!='default'){
					filtertype	=	'pdas';
					navigation.openDrawer();
					 r = {
						latitude: global.major_port_latitude,
						longitude: global.major_port_longitude,
						latitudeDelta: global.major_port_latitudeDelta,
						longitudeDelta: global.major_port_longitudeDelta
					};	
					 markerID		= global.major_port_markerID;
					 pdaamount		= global.pdaamount;
					 dwt			= 10000;
				}
			}else if(this.props.route.params.activevessels){
				 filtertype	=	'activevessels';
				 data	=	this.props.route.params.activevessels;
			}else if(this.props.route.params.factories){
				 filtertype	=	'factories';
				 data	=	this.props.route.params.factories;
			}else if(this.props.route.params.companies){
				 filtertype	=	'companies';
				 data	=	this.props.route.params.companies;
			}else if(this.props.route.params.cargos){
				 filtertype	=	'cargos';
				 data	=	this.props.route.params.cargos;
			}
		}		 
	  
		return (
		<View style={styles.container} key={this.state.uniqueValue}>
			{/*<PortSlider navigation={navigation} />*/}
			<Map style={Platform.OS=='web' ? null :styles.map} region={r} markerID={markerID} pdaamount={pdaamount} dwt={dwt} navigation={navigation} />
			<Results childName={data} navigation={navigation} filtertype={filtertype}/>
		</View>   
		)
	  }
	}	

const styles = StyleSheet.create({
 container: {
   flex:1,
   height,
   width
 },
 NewPostIcon:{
	 position:'absolute',bottom:290,right:40,zIndex:5
 },
 map: {
   ...StyleSheet.absoluteFillObject,zIndex:0,position:'absolute',top:0
 },
  center: {
	flex: 1,width: '100%',backgroundColor:'#FFFFFF',justifyContent: 'center',position:'relative'
  },
  placeholder:{
	  position:'absolute',right:10,top:10,color:'#146eb4',fontSize:15
  },
  button:{
    backgroundColor:'#146eb4',flex: 1,justifyContent: 'center',
  },
  inputstyle:{
	  fontSize:12,justifyContent: 'flex-start', alignItems: 'flex-start',padding:10,zIndex: 1
  },
   scrollView: {
    backgroundColor: '#FFFFFF',height:height+50,flex:1,zIndex: 10
  },
  title_new: {
	backgroundColor: '#F90',
	color: '#fff',
	padding: 10,
	width: '100%',fontSize: 16,alignItems: 'flex-start',justifyContent: 'flex-start',flex:2
  },
  containerStyle:{
	height:70  
  }
});


export default HomeScreen;