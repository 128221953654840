import * as React from 'react';
import { StyleSheet, Text, View, Dimensions,TouchableOpacity } from 'react-native';
import { useNavigation } from '@react-navigation/native';
import { Button,Header } from 'react-native-elements';
import Icon from 'react-native-vector-icons/Feather';
import AutoCompleteComponent from './Autocomplete.js';

	function AutoCompleteComp(){
		return (
			<View style={styles.autocompleteContainer}>
				<AutoCompleteComponent />
			</View>
		);
	}
	
	
const styles = StyleSheet.create({
   autocompleteContainer: {
    flex: 1,
    left: 0,
    position: 'absolute',
    right: 0,
    top: 0,
    zIndex: 1
  }
});


export default function HeaderComponent() {

  return (
    <Header
	  centerComponent={<AutoCompleteComp />}
	  containerStyle={{
		backgroundColor: '#146eb4',
		justifyContent: 'space-around',
	  }}
	/>
  );
}