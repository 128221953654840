import React, {Component} from 'react';
import { StyleSheet, Button, View, SafeAreaView, Text, Alert,Picker,ActivityIndicator,Dimensions,Platform } from 'react-native';
import { Input} from 'react-native-elements';
import AsyncStorage from '@react-native-community/async-storage';
import Logo from './components/Logo';
import DropDownPicker from 'react-native-dropdown-picker';
import Icon from 'react-native-vector-icons/Feather';
const { width, height } = Dimensions.get('window');

class SignupScreen extends Component {
	constructor(props){
      super(props)
      this.state = {
        userEmail:'',
        userPassword:'',
        userRole:'',
        showbutton:true,
		invalid_login_err:false,
		userEmail_err:false,
        userPassword_err:false,
        email_exist:false,
        freedomain:false,
        role:false,
      }
    }

    userRegister = () => {
		this.setState({showbutton:false});
    	const {userEmail} = this.state;
    	const {userPassword}= this.state;
    	const {userRole} = this.state;
		
		/* VALIDATE INPUTS*/
		
		if(userEmail==''){
			this.setState({userEmail_err:true});
		}else{
			let reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
			if (reg.test(userEmail) === false) {
				this.setState({userEmail_err:true});
				this.setState({showbutton:true});
				return;
			}else {
				this.setState({userEmail_err:false});
			}
		}
		
		if(userPassword==''){
			this.setState({userPassword_err:true});
			this.setState({showbutton:true});
			return;
		}else{
			this.setState({userPassword_err:false});
		}
		
		if(userEmail=='' || userPassword==''){
			this.setState({invalid_login_err: true});
			this.setState({showbutton:true});
			return;
		}

    	fetch('https://app.portda.com/api/signup',{
    		method:'post',
    		headers:{
            'Content-Type'  : 'application/json',
            'PortDA-Allowed-Users': 'PortDA_Team',
            'Authorization' : 'Basic YWRtaW5AcG9ydGRhLmNvbTo1MGNjYWQxMjE3NTI4NDEyNzMwMDdlMTUwMTE3YWViMjI3OTVmMDU4MzllYTg3N2M0NDcxMTc3MTUwYWZlNzlk'
          },
          body:JSON.stringify({
            userEmail:userEmail,
            userPassword:userPassword,
            userRole:userRole
          })
    	})
    	 .then((response)=>response.json())
        .then((responseJson)=>{
			console.log(responseJson);
            if(responseJson.status=='success'){
				this.setState({invalid_login_err: false});
				AsyncStorage.multiSet([
					['user',JSON.stringify(responseJson.user)]
				]);
				
				AsyncStorage.multiGet(['user']).then((data) => {
					let user = data[0][1];
					user = JSON.parse(user);
				});
				this.setState({email_exist: false});
				this.setState({invalid_login_err: false});
				this.setState({freedomain: false});
				this.setState({role: false});
				this.props.navigation.navigate('Home');
			}else if(responseJson.status=='error'){
				this.setState({invalid_login_err: true});
				this.setState({email_exist: false});
				this.setState({freedomain: false});
				this.setState({role: false});
				this.setState({showbutton:true});
			}else if(responseJson.status=='exist'){
				this.setState({invalid_login_err: false});
				this.setState({freedomain: false});
				this.setState({email_exist: true});
				this.setState({role: false});
				this.setState({showbutton:true});
			}else if(responseJson.status=='freedomain'){
				this.setState({invalid_login_err: false});
				this.setState({email_exist: false});
				this.setState({freedomain: true});
				this.setState({role: false});
				this.setState({showbutton:true});
			}else if(responseJson.status=='role'){
				this.setState({invalid_login_err: false});
				this.setState({email_exist: false});
				this.setState({freedomain: false});
				this.setState({role: true});
				this.setState({showbutton:true});
			}
        })
        .catch((error)=>{
          console.error(error);
        });
    }
  render () {
	return ([
      <View style={styles.container}>
		<View style={styles.left}></View>
		<View style={styles.center}>
			<Logo key="logo" />		
			<View style={{ width: (width>748) ? 400 : width,margin: 10}}>
			<Input
			  placeholder='Enter Email'
			  onChangeText = {userEmail => this.setState({userEmail})} style={{outlineColor:"#fff"}} 
			/></View>
			{(this.state.userEmail_err)?<View style={{ width: (width>748) ? 400 : width,margin: 10,zIndex: 1}}><Text style={styles.error}>*Email Invalid / empty</Text></View>:null}
			
			<View style={{ width: (width>748) ? 400 : width,margin: 10}}><Input
			secureTextEntry={true}
			placeholder='Enter password'
			onChangeText = {userPassword => this.setState({userPassword})} style={{outlineColor:"#fff"}} 
		/></View>
		{(this.state.userPassword_err)?<View style={{ width: (width>748) ? 400 : width,margin: 10,zIndex: 1}}><Text style={styles.error}>*Password is empty</Text></View>:null}
			
			<View style={{ width: (width>748) ? 400 : width,margin: 10,zIndex: 2}}><DropDownPicker
				items={[
					{label: 'Cargo Owner', value: '13', icon: () => <Icon name="check" size={18} color="#000" />},
					{label: 'Ship Owner', value: '3', icon: () => <Icon name="check" size={18} color="#000" />},
					{label: 'Ship Agent', value: '2', icon: () => <Icon name="check" size={18} color="#000" />},
					{label: 'Other', value: '12', icon: () => <Icon name="check" size={18} color="#000" />},
				]}
				placeholder="Select Your Role"
				containerStyle={{height: 65}}
				itemStyle={{
					justifyContent: 'flex-start',zIndex: 2
				}}
				dropDownStyle={{backgroundColor: '#fafafa'}}
				onChangeItem={item  => this.setState({userRole: item.value})}
			/></View>
			
			{(this.state.invalid_login_err) && <View style={{ width: (width>748) ? 400 : width,margin: 10,zIndex: 1}} ><Text style={styles.error}>*Username/Password must be filled</Text></View>}
			{(this.state.email_exist) && <View style={{ width: (width>748) ? 400 : width,margin: 10,zIndex: 1}} ><Text style={styles.error}>*Username already exist</Text></View>}
			{(this.state.freedomain) && <View style={{ width: (width>748) ? 400 : width,margin: 10,zIndex: 1}} ><Text style={styles.error}>*Only corporate email id required</Text></View>}
			{(this.state.role) && <View style={{ width: (width>748) ? 400 : width,margin: 10,zIndex: 1}} ><Text style={styles.error}>*Please select your role</Text></View>}
			
			<View style={{ width: (width>748) ? 400 : width,margin: 5,padding: 5,zIndex: 1,backgroundColor: '#146eb4'}}>{(this.state.showbutton) ? <Button title="Signup" color="#146eb4"  onPress={this.userRegister} style={styles.button} />	:	<ActivityIndicator size="large" color="#fff"  />}</View>
			<View style={{ width: (width>748) ? 400 : width,margin: 5,padding: 5,zIndex: 1,backgroundColor: '#146eb4'}}><Button title="Login" color="#146eb4"  onPress={()=>this.props.navigation.navigate('Login')} style={styles.button} /></View>
			<View style={{ width: (width>748) ? 400 : width,margin: 5,padding: 5,zIndex: 1,backgroundColor: '#146eb4',zIndex: 1}} ><Button title="Home" key={2}  color="#146eb4" onPress={()=>this.props.navigation.navigate('Home')} style={styles.button} /></View>
			
			
		</View>
        <View style={styles.right}></View>
      </View>,
	  <View style={{position: 'absolute', left: 0, right: 0, bottom: 0, textAlign:'center',padding:5}}><Text>© 2020 PortDA.com. All rights reserved | Powered by UGSA GROUP</Text></View>
    ]);
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
	flexDirection: 'row',
    width: '100%',
    height: '100%',
	backgroundColor:'#FFFFFF'
  },
  left: {
	width: '30%',
	height: '100%',
  },
  center: {
	justifyContent: 'center',
	alignItems: 'center',
	width: '40%',
	height: '100%',
  },
  right: {
	justifyContent: 'center',
	alignItems: 'center',
	width: '30%',
	height: '100%',
  },
  button: {
	backgroundColor : '#000'
  },
  error: {
    color:'red',
	fontSize:12,
  },
  success: {
    color:'white',
	backgroundColor:'green',
	fontSize:12,
	padding:5
  }
});

export default SignupScreen;