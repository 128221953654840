import * as global from './global';
global.global_world_ports	=	[];


class Markers {	
	getallmarkers(){
		return fetch('https://app.portda.com/api/get-all-active-ports', {
			  method: 'POST',
			  headers: {
				Accept  : 'application/json',
				'Content-Type'  : 'application/json',
				'PortDA-Allowed-Users': 'PortDA_Team',
				'Authorization' : 'Basic YWRtaW5AcG9ydGRhLmNvbTo1MGNjYWQxMjE3NTI4NDEyNzMwMDdlMTUwMTE3YWViMjI3OTVmMDU4MzllYTg3N2M0NDcxMTc3MTUwYWZlNzlk'
			  },
			})
			.then((response) => response.json())
			.then((json) => {
				global.global_world_ports = json.ports;
				return json.ports;
			});		
	}
	
	
	getpopularports(){
		return fetch('https://app.portda.com/api/get-popular-ports', {
			  method: 'POST',
			  headers: {
				  Accept  : 'application/json',
				'Content-Type'  : 'application/json',
				'PortDA-Allowed-Users': 'PortDA_Team',
				'Authorization' : 'Basic YWRtaW5AcG9ydGRhLmNvbTo1MGNjYWQxMjE3NTI4NDEyNzMwMDdlMTUwMTE3YWViMjI3OTVmMDU4MzllYTg3N2M0NDcxMTc3MTUwYWZlNzlk'
			  },
			})
			.then((response) => response.json())
			.then((json) => {
				return json.ports;
			});		
	}
	
	getmajorportspda(){		
		return fetch('https://app.portda.com/api/get-major-ports-pda', {
			  method: 'POST',
			  headers: {
				  Accept  : 'application/json',
				'Content-Type'  : 'application/json',
				'PortDA-Allowed-Users': 'PortDA_Team',
				'Authorization' : 'Basic YWRtaW5AcG9ydGRhLmNvbTo1MGNjYWQxMjE3NTI4NDEyNzMwMDdlMTUwMTE3YWViMjI3OTVmMDU4MzllYTg3N2M0NDcxMTc3MTUwYWZlNzlk'
			  },
			})
			.then((response) => response.json())
			.then((json) => {
				return json.majorports;
			});		
	}
}
const m = new Markers();
export default m;