global.global_country_id=0;
global.global_major_port_id=0;
global.user_id=0;
global.global_major_port_name=0;
global.global_major_country_name=0;
global.pdaamount=0;
global.global_port_id=0;
global.global_port_name=0;
global.global_map=0;
global.gmarkers=0;
global.gmarkerspopup=0;
global.global_filter_data_pdas=null;
global.global_filter_data_posts=null;
global.global_filter_data_activevessels=null;
global.global_filter_data_companies=null;
global.global_world_ports=[];

global.port_expenses_total=null;
global.port_expenses=null;
global.cargo_expenses=null;
global.other_expenses=null;
global.total_days=null;
global.local_currency=null;

global.major_port_latitude=0;
global.major_port_longitude=0;
global.major_port_latitudeDelta=0;
global.major_port_longitudeDelta=0;
global.major_port_markerID=0;
global.navigation=null;