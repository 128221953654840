import React, {Component} from 'react';
import {
	Platform,
	Text,
	View,
	StyleSheet,Dimensions,SafeAreaView,ScrollView,TouchableOpacity,TouchableHighlight
} from 'react-native';
const { width, height } = Dimensions.get('window');
import PdaForm from './PdaForm.js';
import Icon from 'react-native-vector-icons/Feather';
import m from './Markers.js';
import * as global from './global';

	const flytoPort=(map,data)=>{
		global.global_port_id			=	data[0];
		global.global_major_port_id			=	data[0];
		global.global_country_id		=	data[1];
		global.global_port_name		=	data[2];
		map.flyTo({
			center: global.gmarkers['marker_'+global.global_port_id].getLngLat(), zoom: 8, speed: 3, curve: 1, easing(t) {
			  return t;
			}
		});
		console.log(global.gmarkers['marker_'+global.global_port_id].isOpen());
		global.gmarkers['marker_'+global.global_port_id].togglePopup();
	}
	
	class PortSlider extends Component {
		constructor(props) {
		  super(props);
			this.state = {
				ports: [],
				scrollViewWidth:0,
				currentXOffset:0,
				pressed: false,
				backgroundColor2: '#fff',
				color2: '#000',
				color3: 'lightgrey',
				selectedButton: null,
			};
			this.unmounted	=	false;
		  }	
		  
		componentDidMount() {
			const { ports } = this.state;
			console.log(ports);
			console.log(this.unmounted);
			if (this.unmounted) {
				return;
			}
			m.getpopularports().then(response => {
				const files =	[];
				response.map((marker) => {
					files.push({
						name: marker.port_name+'-'+marker.country_name,
						id: [marker.port_id,marker.country_id,marker.port_name,marker.lat,marker.lng]
					})
				});
				if(files.length>0){
					this.setState({ports: files});
				}
			
			});
		}
		
		componentWillUnmount () {
			this.unmounted = true
		}
		
		
		
		navigateToPort(data,key) {
			console.log("step 0");
			if(data){				
				this.setState({ pressed: true,backgroundColor2: '#f90', color2: '#fff',color3: '#f90',selectedButton:key});
				
				console.log("step 1");	
				
				const id	=	data.id;
				const port_name		=	data.name;
				const { navigation } = this.props;
				Platform.OS === "web" ?
				
				flytoPort(global.global_map,id)	:
				
				console.log("step 2");		
				global.global_port_id				=	data.id[0];
				global.global_country_id			=	data.id[1];
				global.major_port_latitude			=	parseFloat(data.id[3]);
				global.major_port_longitude 		= 	parseFloat(data.id[4]);
				global.major_port_latitudeDelta 	= 	Math.round((data.id[3]-1) * 10) / 10;
				global.major_port_longitudeDelta 	= 	Math.round((data.id[4]-1) * 10) / 10;
				global.major_port_markerID 			= 	'marker_'+data.id[0];
				console.log("step 2.5");
				/* NOW CREATE A TEST ENQUIRY*/
				new PdaForm().pdaSubmit(navigation);
			}
		}
		

		
		_handleScroll = (event) => {
			const newXOffset = event.nativeEvent.contentOffset.x
			this.setState({currentXOffset:newXOffset})
		}

		leftArrow = () => {
			let eachItemOffset = this.state.scrollViewWidth / 30;
			let _currentXOffset =  this.state.currentXOffset - eachItemOffset;
			this.refs.scrollView.scrollTo({x: _currentXOffset, y: 0, animated: true});
		}

		rightArrow = () => {
			let eachItemOffset = this.state.scrollViewWidth / 30;
			let _currentXOffset =  this.state.currentXOffset + eachItemOffset;
			this.refs.scrollView.scrollTo({x: _currentXOffset, y: 0, animated: true})
		}
				
	  render() {		
		const { ports,backgroundColor2,color2,selectedButton } = this.state;		
		
		return (
			<SafeAreaView style={styles.container}>
				{Platform.OS === 'web' ? <TouchableHighlight
						style={{alignItems: 'flex-start',marginTop:15}}
						onPress={this.leftArrow}>
						<Icon name="arrow-left" size={25} color="black" />
				</TouchableHighlight> : null}
					<ScrollView style={styles.scrollView} pagingEnabled={true} horizontal={true} ref="scrollView" showsHorizontalScrollIndicator={false} onScroll={this._handleScroll}  onContentSizeChange={(w, h) => this.setState({scrollViewWidth:w})} scrollEnabled={true} scrollEventThrottle={1}>
						{ports.map((prop, key) => (
							<TouchableOpacity style={{zIndex:1}} onPress={() =>  {this.navigateToPort(prop,prop.id[0])}} key={prop.id[0]}>
									<Text style={{backgroundColor:(this.state.selectedButton == prop.id[0]) ? this.state.backgroundColor2 : '#fff',color:this.state.selectedButton === prop.id[0] ? this.state.color2 : '#000', fontSize: 15,borderWidth: 1,borderColor: this.state.selectedButton === prop.id[0] ? this.state.color3 : 'lightgrey',borderRadius: 1,margin:5,padding:5}}  >
									  {prop.name}
									</Text>
							</TouchableOpacity>
						  ))}
					</ScrollView>
					{Platform.OS === 'web' ? <TouchableHighlight
						style={{alignItems: 'flex-start',marginTop:15}}
						onPress={this.rightArrow}>
						<Icon name="arrow-right" size={25} color="black" />
					</TouchableHighlight> : null}
			</SafeAreaView>
		)
	  }
	}

const styles = StyleSheet.create({
 container: {
    height:55,position:'absolute',left:0,zIndex:10,backgroundColor:'#fff',top:0,flex:1,flexDirection: 'row',width
  },
  scrollView: {
    width:'50%',marginTop:10
  }
});


/*export default function(props) {
  const navigation = useNavigation();
  return <PortSlider {...props} navigation={navigation} />;
}*/

export default PortSlider;