import React from 'react';
import ReactDOM from 'react-dom';
import mapboxgl from 'mapbox-gl';
import m from './Markers.js';
import PdaForm from './PdaForm.js';
import * as global from './global';
import { useNavigation } from '@react-navigation/native';
	
mapboxgl.accessToken = 'pk.eyJ1IjoieWFzZWVuNzg2IiwiYSI6ImNrM3lmdnd5bDBrczMzbHA2NTVxZXBtd2IifQ.y6PxUFR7lO3DLTi9Mg6sHw';
			
	const flytoPort=(map,port_id)=>{
		map.flyTo({
			center: global.gmarkers['marker_'+port_id].getLngLat(), zoom: 7, speed: 3, curve: 1, easing(t) {
			  return t;
			}
		});
	}
	
	class Map extends React.Component {
	  constructor(props) {
		super(props);
		this.state = {
		  lng: 55.031523,
		  lat: 25.0236299,
		  zoom: 1
		};
		
		global.global_country_id=0;
		global.global_port_id=0;
		global.global_port_name=0;
		global.global_map=null;
		global.gmarkers=[];
		global.global_world_ports=[];
		global.gmarkerspopup=[];
	  }
	  
	componentDidMount() {
		global_map = new mapboxgl.Map({
		  container: this.mapContainer,
		  style: 'mapbox://styles/yaseen786/ck6g1g08m09nr1ijrr8im3u9d',
		  center: [this.state.lng, this.state.lat],
		  zoom: this.state.zoom
		});
		
		m.getallmarkers().then((response) => 
			
			response.forEach(function(marker) {
				global.global_world_ports.push(marker);
				let lng=marker.lng;
				let lat=marker.lat;
				let popupOffsets = {
								   'top': [1, 1]
								 };
				let popup = new mapboxgl.Popup({offset: popupOffsets}).setLngLat([lng,lat]).setHTML('<div style="background: rgb(20, 110, 180);margin:0;color: #fff;font-size: 22px;text-align: left !important; padding: 10px !important; line-height: 1.5 !important;">'+marker.port_name+'</div>');

				  // create a HTML element for each feature
				  let el = document.createElement('div');
				  el.className = 'marker';

					global.gmarkerspopup['marker_'+marker.port_id] = popup;
					global.gmarkers['marker_'+marker.port_id] = new mapboxgl.Marker(el)
					.setLngLat([lng,lat])
					.setPopup(popup) // add popups
					.addTo(global_map);				
					
					global.global_map	=	global_map;
					
					global.gmarkers['marker_'+marker.port_id].getElement().addEventListener('click', ()=> {					 
					global.global_major_port_id		=	marker.port_id;
					global.global_port_id		=	marker.port_id;
					global.global_country_id	=	marker.country_id;
					global.global_port_name	=	marker.port_name;
					flytoPort(global_map,marker.port_id);
					new PdaForm().pdaSubmit(global.navigation);
				});
			})		
		);
	}
	

	  render() {
		  const mapContainer = {
			  width: "100%",
			  height: "100vh",
			  zIndex:1,
			  position:'absolute'
			};
		return (
			<div>
				<div ref={el => this.mapContainer = el} className="mapContainer" />
			</div>
		)
	  }
	}


export default Map;