import React, { Component } from 'react';
import {
  Platform,
  StyleSheet,
  Text,
  View,
  ScrollView,
  SafeAreaView,
  Image,
  TouchableOpacity,
  FlatList
} from 'react-native';
import Constants from 'expo-constants';
import AsyncStorage from '@react-native-community/async-storage';
import AssetExample from './components/AssetExample';
// import Icon from 'react-native-vector-icons/Feather';
import Icon from 'react-native-vector-icons/AntDesign';
import { Card, ListItem, Header, Button } from 'react-native-elements';
import Footer from "./Footer";
import {
  createDrawerNavigator,
  DrawerContentScrollView,
  DrawerItemList,
  DrawerItem,
} from '@react-navigation/drawer';
import { NavigationContainer } from '@react-navigation/native';
import * as global from './components/global';

export default class ProfileScreen extends Component {

    constructor(props){
      super(props);
      this.state={
        data:[]
      }
    }
    
    componentDidMount=()=>{
       AsyncStorage.multiGet(['user']).then((Userdata) => {
          let user = Userdata[0][1];
          user = JSON.parse(user);
          let userId = user.id;
          fetch('https://app.portda.com/api/user-info/'+userId,{
              method:'post',
              headers: {
                              'Content-Type'  : 'application/json',
                              'PortDA-Allowed-Users': 'PortDA_Team',
                              'Authorization' : 'Basic YWRtaW5AcG9ydGRhLmNvbTo1MGNjYWQxMjE3NTI4NDEyNzMwMDdlMTUwMTE3YWViMjI3OTVmMDU4MzllYTg3N2M0NDcxMTc3MTUwYWZlNzlk'
                        }
          })
          .then((response) => response.json())
            .then((responseJson) => {
              console.log(responseJson);
              this.setState({
                  data: responseJson.userInfo
                  })
            })
            .catch((error) => console.error(error))
            .finally(() => {
              this.setState({isLoading: false});
            });
        });
     }
		 _logout	= async () => {
			  let keys = ['user'];
			  
			  await AsyncStorage.multiRemove(keys, (err) => {
				global.user_id	=	0;
			  
			  this.props.navigation.navigate('Login',{logout: 1});
				
			  });
		}
       render(){
        const { data, isLoading } = this.state;

            return (
              <View style={styles.container}>
                  <View style={styles.profile}>
                    <View style={styles.profileInfo}>						
						  <View>
							<FlatList
								  data={data}
								  extraData={this.state}
								  renderItem={({ item }) => (
									<Text style={{color:'#146eb4',fontSize:20}}>
									  {item.username}({item.email})
									</Text>
								  )}
								  keyExtractor={(item, index) => index}
								/>
						  </View>
					</View>
					 <View style={styles.profileInfo}>
						 <View>
								<TouchableOpacity 
								  onPress={()=>{this.props.navigation.navigate('ChangePassword')}} 
								>   <Icon name="edit" size={20} color="#146eb4">Change Password</Icon>
								</TouchableOpacity>
						  </View>
                    </View>
                    <View style={styles.profileInfo}>
						<View>
						  <TouchableOpacity
							  style={{
								paddingTop: 5,
								paddingBottom: 5,
								paddingLeft: 15,
								paddingRight: 15,
								alignSelf: 'center',
							  }}
							  onPress={this._logout}>
							  <Icon name="logout" size={20} color="#146eb4" >Logout</Icon>
							</TouchableOpacity>
						</View>                    
                    </View>                    
                  </View>
              </View>
            );
           }
          };


const styles = StyleSheet.create({
  container: {
    justifyContent: 'center',
    width: '100%',
    height: '100%',
  },
  scrollView: {
    flex: 3,
    backgroundColor: 'white',
  },
  header: {
    backgroundColor: '#f90',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: 5,
    paddingTop: 20,
  },
  profile: {
    flex: 1,
    backgroundColor: '#fff',
  },
  middle: {
    flex: 5,
    backgroundColor: 'white',
  },
  profileInfo: {
    flex: 1,
    marginTop: 0,alignItems: 'center',justifyContent: 'center',
  },
  profileDescription: {
    flex: 2,
    marginBottom: 10,
    marginLeft: 10,
  },
  headerText: {
    fontSize: 20,
    textAlign: 'center',
    flex: 1,
    color: '#fff',
  },
  profileText: {
    fontSize: 20,
    color: '#fff',
  },
  whiteText: {
    color: '#fff',
  },
  tinyLogo: {
    width: 75,
    height: 75,
    borderRadius: 200 / 2,
    borderWidth: 0.5,
    borderColor: 'black',
    marginLeft: 10,
  },
  gridImage: {
    width: '33.33%',
    height: 150,
    borderColor: 'black',
    borderWidth: 0.5,
  },
});
